import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { PermissionControlService } from '../services/permission-control.service';

export const adminGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const permissionService = inject(PermissionControlService);
    const router = inject(Router);

    return permissionService.isAdmin().pipe(
        map(isAdmin => {
            if (isAdmin) {
                return true;
            } else {
                return router.createUrlTree(['/permits']);
            }
        })
    );
};
