import {Injectable} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, map, Observable, of, Subject, switchMap, takeUntil} from "rxjs";
import {CurrentService, Song, SongList} from "../models";
import {ChorubyFirestoreService} from "./choruby-firestore.service";

@Injectable({
    providedIn: 'root'
})
export class CurrentSongServiceService {
    private currentServiceSubject: BehaviorSubject<CurrentService | null>;
    private currentList: BehaviorSubject<SongList | null>;
    private currentSong: BehaviorSubject<Song | null>;
    private nextSong: BehaviorSubject<Song | null>;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private firestore: ChorubyFirestoreService,
    ) {
        this.currentServiceSubject = new BehaviorSubject<CurrentService | null>(null);
        this.currentList = new BehaviorSubject<SongList | null>(null);
        this.currentSong = new BehaviorSubject<Song | null>(null);
        this.nextSong = new BehaviorSubject<Song | null>(null);
        this.loadCurrentService();
        this.loadCurrentList();
        this.loadCurrentSong();
        this.loadNextSong();
    }

    private loadCurrentSong() {
        this.currentServiceSubject
            .pipe(map(service => service?.songUid),
                distinctUntilChanged(),
                switchMap(songUid => songUid ? this.firestore.getSong$(songUid) : of(null)))
            .pipe(takeUntil(this.destroy$))
            .subscribe(song => {
                this.currentSong.next(song);
            });
    }

    private loadNextSong() {
        this.currentServiceSubject
            .pipe(map(service => service?.nextSongUid),
                distinctUntilChanged(),
                switchMap(songUid => songUid ? this.firestore.getSong$(songUid) : of(null)))
            .pipe(takeUntil(this.destroy$))
            .subscribe(song => {
                this.nextSong.next(song);
            });
    }

    public getCurrentSong(): Observable<Song | null> {
        return this.currentSong.asObservable();
    }

    public getNextSong(): Observable<Song | null> {
        return this.nextSong.asObservable();
    }

    // }
    public getCurrentService(): Observable<CurrentService | null> {
        return this.currentServiceSubject.asObservable();
    }

    public getCurrentSongServiceList(): Observable<SongList | null> {
        return this.currentList.asObservable();
    }

    //returns currentSongServiceListId as string
    public getCurrentSongServiceListId() {
        return this.currentList.value?.uid || '';
    }


    public getCurrentVerseIndex(): Observable<number> {
        return this.currentServiceSubject.pipe(map((currentService) => {
            return currentService?.verseIndex || 0;
        }), distinctUntilChanged());
    }

    public getNextVerseIndex(): Observable<number> {
        return this.currentServiceSubject.pipe(map((currentService) => {
            return currentService?.nextVerseIndex || 0;
        }), distinctUntilChanged());
    }

    public getCurrentSongKey(): Observable<string> {
        return this.currentServiceSubject.pipe(map((currentService) => {
            return currentService?.key || '';
        }), distinctUntilChanged());
    }
    public getNextSongKey(): Observable<string> {
        return this.currentServiceSubject.pipe(map((currentService) => {
            return currentService?.nextKey || '';
        }), distinctUntilChanged());
    }

    public getBlankPresentation(): Observable<boolean> {
        return this.currentServiceSubject.pipe(
            map((currentService) => Boolean(currentService?.blank)),
            distinctUntilChanged()
        );
    }

    private loadCurrentService(): void {
        this.firestore.getCurrentServiceDetails$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(service => {
                this.currentServiceSubject.next(service);
            });
    }

    private loadCurrentList(): void {
        this.currentServiceSubject
            .pipe(map(service => service?.list), distinctUntilChanged(), switchMap(list => list ? this.firestore.getSongList$(list) : of(null)))
            .subscribe(list => {
                this.currentList.next(list);
            });
    }


}