import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, Observable, Subscription} from "rxjs";
import {RemoveChordsParserPipe} from "../../../core/services/chord-mgmt/remove-chords-parser.pipe";
import {ChorubyFirestoreService} from "../../../core/services/choruby-firestore.service";
import {SongsCacheService} from "../../../core/services/songs-cache.service";
import {ActivatedRoute, NavigationEnd, Router,} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {ProfileService} from "../../../core/services/profile.service";
import {CustomMessageService} from "../../../core/services/custom-message.service";
import {CurrentSongServiceService} from "../../../core/services/current-song-service.service";
import {ChordsLyricsParserPipe} from "../../../core/services/chord-mgmt/chords-lyrics-parser.pipe";
import {ViewportScroller} from "@angular/common";

@Component({
    selector: 'app-book-view',
    templateUrl: './book-view.component.html',
    providers: [CustomMessageService, ProfileService]
})
export class BookViewComponent implements OnInit, OnDestroy {

    protected songs$: Observable<any[]>; //todo: replace with Song model
    private subscriptions: Subscription[] = [];
    protected chordsLyricsParserPipe: ChordsLyricsParserPipe;
    protected removeChordsParserPipe: RemoveChordsParserPipe;
    protected showChords: boolean;
    protected showNashville: boolean;

    constructor(
        private firestore: ChorubyFirestoreService,
        private songsCacheService: SongsCacheService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private viewportScroller: ViewportScroller,
        private titleService: Title,
        private profileService: ProfileService,
        private messageService: CustomMessageService,
        private currentService: CurrentSongServiceService,
    ) {
        this.chordsLyricsParserPipe = new ChordsLyricsParserPipe();
        this.removeChordsParserPipe = new RemoveChordsParserPipe();
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.activatedRoute.fragment.subscribe(fragment => {
                if (fragment) this.viewportScroller.scrollToAnchor(fragment);
            });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription?.unsubscribe());
    }


    ngOnInit(): void {
        console.log('this is triggered');
        this.titleService.setTitle('Songs - Choruby');
        this.getSongs();
        this.subscriptions.push(
            this.profileService.getShowChords().subscribe((showChords) => {
                this.showChords = showChords;
            }));

        this.subscriptions.push(
            this.profileService.getShowNashville().subscribe((show) => {
                this.showNashville = show;
            }));
    }

    private getSongs() {
        this.songs$ = this.songsCacheService.getSongs();
        //     .pipe(
        //     map(songs => {
        //       return songs.map(song => {
        //         const lyrics = song.lyrics.map((lyric: { content: string }) => {
        //         }).join(' ');
        //         return {...song, songContent: lyrics};
        //       });
        //     })
        // );
    }

    parseLyricsContent(content: string, key: string): string {
        if (this.showChords) {
            return this.chordsLyricsParserPipe.transform(content, key, this.showNashville);
        } else {
            return this.removeChordsParserPipe.transform(content);
        }
    }

    protected addSongToCurrentSelectedList(songUid: string) {
        this.firestore.addSongToList(songUid, this.profileService.getSelectedSongListId() ?? '').then(() => {
            this.messageService.showSuccess('Success', 'Song added to list');
        }).catch((error) => {
            this.messageService.showError('Error', "Couldn't add song to the list" + error);
        });
    }

    protected addSongToPresentedList(songUid: string) {
        this.firestore.addSongToList(songUid, this.currentService.getCurrentSongServiceListId() ?? '').then(() => {
            this.messageService.showSuccess('Success', 'Song added to list');
        }).catch((error) => {
            this.messageService.showError('Error', "Couldn't add song to the list" + error);
        });
    }

    protected playSongNow(uid: string, key: string) {
        this.addSongToPresentedList(uid);
        this.firestore.setGlobalCurrentSongs(uid, 0, key)
            .catch((error) => {
                this.messageService.showError("Error setting current verse", error);
            });
        this.firestore.setGlobalNextVerse(uid, 1, key).catch((error) => {
            this.messageService.showError("Error setting next verse", error);
        });
        this.router.navigate(['/dashboard']);
    }
}
