import {Injectable} from '@angular/core';
import {Functions, httpsCallable} from "@angular/fire/functions";


@Injectable({
  providedIn: 'root'
})
export class ChorubyFunctionsService {

  constructor(
    private functions: Functions,
  ) {
  }

  createNewSong() {
    return httpsCallable(this.functions, 'createNewSong')({message: "createSong"});
  }
}
