import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn} from '@angular/router';
import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import {ChorubyFirestoreService} from "../services/choruby-firestore.service";

export const profileCompletedAndVerifiedGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const firestore = inject(ChorubyFirestoreService);
  const router = inject(Router);

  return firestore.getUserProfile$().pipe(
      map((profile) => {
        if (profile?.profileCompleted && profile?.verified) {
          return true;
        } else {
          return router.createUrlTree(['/profile']);
        }
      })
  );
};
