import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomMessageService} from "../../../core/services/custom-message.service";
import {ChorubyFirestoreService} from "../../../core/services/choruby-firestore.service";
import {Song} from "../../../core/models";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from '@angular/common';
import {Observable, Subscription} from "rxjs";
import {ChordsLyricsParserPipe} from "../../../core/services/chord-mgmt/chords-lyrics-parser.pipe";
import {RemoveChordsParserPipe} from "../../../core/services/chord-mgmt/remove-chords-parser.pipe";
import {ProfileService} from "../../../core/services/profile.service";
import {CurrentSongServiceService} from "../../../core/services/current-song-service.service";

@Component({
    selector: 'app-song-view',
    templateUrl: './song-view.component.html',
    providers: [CustomMessageService, ProfileService]
})
export class SongViewComponent implements OnInit, OnDestroy {

    protected song: Song;
    protected auditLogs$: Observable<any[]>;
    private subscriptions: Subscription[] = [];
    protected chordsLyricsParserPipe: ChordsLyricsParserPipe;
    protected removeChordsParserPipe: RemoveChordsParserPipe;
    protected showChords: boolean;
    protected showNashville: boolean;


    constructor(
        private messageService: CustomMessageService,
        private firestore: ChorubyFirestoreService,
        private titleService: Title,
        private route: ActivatedRoute,
        private router: Router,
        private profileService: ProfileService,
        private currentService: CurrentSongServiceService,
        private location: Location
    ) {
        this.chordsLyricsParserPipe = new ChordsLyricsParserPipe();
        this.removeChordsParserPipe = new RemoveChordsParserPipe();
    }

    async ngOnInit() {
        this.titleService.setTitle('Song View');
        const songId = this.route.snapshot.paramMap.get('songId');
        await this.getSong(songId ?? '');
        this.subscriptions.push(
            this.profileService.getShowChords().subscribe((showChords) => {
                this.showChords = showChords;
            }));

        this.subscriptions.push(
            this.profileService.getShowNashville().subscribe((show) => {
                this.showNashville = show;
            }));

    }

    goBack() {
        this.location.back();  // <-- go back to previous location on back button click
    }

    async getSong(songNumber: string) {
        this.subscriptions.push(
            this.firestore.getSong$(songNumber).subscribe(song => {
                this.song = song;
                this.getAuditLogs();
            })
        );
    }

    private getAuditLogs() {
        this.auditLogs$ = this.firestore.getAuditLog$('Song', this.song.uid);
    }

    parseLyricsContent(content: string, key?: string): string {
        if (this.showChords) {
            return this.chordsLyricsParserPipe.transform(content, key, this.showNashville);
        } else {
            return this.removeChordsParserPipe.transform(content);
        }
    }


    countChorusBefore(index: number): number {
        let count = 0;
        for (let i = 0; i < index; i++) {
            if (this.song.lyrics[i].type === 'Chorus') {
                count++;
            }
        }
        return count;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s?.unsubscribe());
    }


    protected addSongToCurrentSelectedList(songUid: string) {
        this.firestore.addSongToList(songUid, this.profileService.getSelectedSongListId() ?? '').then(() => {
            this.messageService.showSuccess('Success', 'Song added to list');
        }).catch((error) => {
            this.messageService.showError('Error', "Couldn't add song to the list" + error);
        });
    }

    protected addSongToPresentedList(songUid: string) {
        this.firestore.addSongToList(songUid, this.currentService.getCurrentSongServiceListId() ?? '').then(() => {
            this.messageService.showSuccess('Success', 'Song added to list');
        }).catch((error) => {
            this.messageService.showError('Error', "Couldn't add song to the list" + error);
        });
    }

    protected playSongNow(uid: string, key: string) {
        this.addSongToPresentedList(uid);
        this.firestore.setGlobalCurrentSongs(uid, 0, key)
            .catch((error) => {
                this.messageService.showError("Error setting current verse", error);
            });
        this.firestore.setGlobalNextVerse(uid, 1, key).catch((error) => {
            this.messageService.showError("Error setting next verse", error);
        });
        this.router.navigate(['/dashboard']);
    }

    async nextSong() {
        let songNumber = Number(this.song.uid);
        songNumber += 1; // increment song number

        // Convert it back to a string and add leading zeros if necessary
        let paddedSongNumber = String(songNumber).padStart(4, '0');

        // Get the new song and update the URL
        this.router.navigate(['song/' + paddedSongNumber + '/view'])
        await this.getSong(paddedSongNumber);
    }

    async previousSong() {
        let songNumber = Number(this.song.uid);
        songNumber = songNumber - 1; // decrement song number

        // Convert it back to a string and add leading zeros if necessary
        let paddedSongNumber = String(songNumber).padStart(4, '0');

        // Get the new song and update the URL
        this.router.navigate(['song/' + paddedSongNumber + '/view']);
        await this.getSong(paddedSongNumber);
    }

}
