import {Component, OnInit, ViewChild} from '@angular/core';
import {CustomMessageService} from "../../core/services/custom-message.service";
import {map, Observable} from "rxjs";
import {Table} from "primeng/table";
import {ChorubyFirestoreService} from "../../core/services/choruby-firestore.service";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {RemoveChordsParserPipe} from "../../core/services/chord-mgmt/remove-chords-parser.pipe";
import {ProfileService} from "../../core/services/profile.service";
import {CurrentSongServiceService} from "../../core/services/current-song-service.service";
import {SongsCacheService} from "../../core/services/songs-cache.service";

@Component({
    selector: 'app-songs',
    templateUrl: './songs.component.html',
    providers: [CustomMessageService]
})
export class SongsComponent implements OnInit {

    @ViewChild('songsTable') songsTable: Table;
    protected songs$: Observable<any[]>; //todo: replace with Song model
    private removeChordsParserPipe: RemoveChordsParserPipe;
    protected tableHeaders = [
        {field: 'uid', label: 'Number'},
        {field: 'title', label: 'Title'},
        {field: 'firstVerse', label: 'First Verse', hiddenOnMobile: true},
        {field: 'key', label: 'Key'},
        {field: 'tempo', label: 'Tempo'},
        {label: 'Actions'}
    ];

    constructor(
        private firestore: ChorubyFirestoreService,
        private songsCacheService: SongsCacheService,
        private router: Router,
        private titleService: Title,
        private profileService: ProfileService,
        private messageService: CustomMessageService,
        private currentService: CurrentSongServiceService,
    ) {
        this.removeChordsParserPipe = new RemoveChordsParserPipe();
    }


    ngOnInit(): void {
        this.titleService.setTitle('Songs - Choruby');
        this.getSongs();
    }

    private getSongs() {
        this.songs$ = this.songsCacheService.getSongs().pipe(
            map(songs => {
                return songs.map(song => {
                    const lyrics = song.lyrics.map((lyric: { content: string }) => {
                        return this.removeChordsParserPipe.transform(lyric.content);
                    }).join(' ');
                    const firstVerse = this.removeChordsParserPipe.transform(song.lyrics[0]?.content ?? 'Error loading');

                    return {...song, songContent: lyrics, firstVerse: firstVerse};
                });
            })
        );
    }

    protected addSongToCurrentSelectedList(songUid: string) {
        this.firestore.addSongToList(songUid, this.profileService.getSelectedSongListId() ?? '').then(() => {
            this.messageService.showSuccess('Success', 'Song added to list');
        }).catch((error) => {
            this.messageService.showError('Error', "Couldn't add song to the list" + error);
        });
    }

    protected addSongToPresentedList(songUid: string) {
        this.firestore.addSongToList(songUid, this.currentService.getCurrentSongServiceListId() ?? '').then(() => {
            this.messageService.showSuccess('Success', 'Song added to list');
        }).catch((error) => {
            this.messageService.showError('Error', "Couldn't add song to the list" + error);
        });
    }

    protected playSongNow(uid: string, key: string) {
        this.addSongToPresentedList(uid);
        this.firestore.setGlobalCurrentSongs(uid, 0, key)
            .catch((error) => {
                this.messageService.showError("Error setting current verse", error);
            });
        this.firestore.setGlobalNextVerse(uid, 1, key).catch((error) => {
            this.messageService.showError("Error setting next verse", error);
        });
        this.router.navigate(['/dashboard']);
    }


    protected applyFilterGlobal($event: any) {
        this.songsTable.filterGlobal(($event.target as HTMLInputElement).value, 'contains');
    }

    protected clear(table: Table) {
        table.clear();
    }

    setTempo(uid: string, tempo: string) {
        this.firestore.setGlobalTempo(uid, tempo).catch((error) => {
            this.messageService.showError("Error setting tempo", error);
        });
    }
}

