import {Injectable, Optional} from '@angular/core';
import {
  Auth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  OAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword
} from "@angular/fire/auth";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    @Optional() private auth: Auth,
    private router: Router
  ) {
  }


  //get the current auth user's id
  get currentUserId(): string {
    return this.auth.currentUser?.email || '';
  }
  //see if the user has a custom claim of admin
  //not sure if this works yet
  get isAdmin(): boolean {
    let isAdmin = false;
    this.auth.currentUser?.getIdTokenResult().then(idTokenResult => {
      if(!!idTokenResult.claims['admin']){
        isAdmin = true;
      }
    });
    return isAdmin;
  }

  async createUserWithEmailAndPassword(email: string, password: string) {
    return await createUserWithEmailAndPassword(this.auth, email, password).then(() => {
      this.redirectSuccessfulLogin();
    });
  }

  async loginWithUserNameAndPassword(email: string, password: string) {
    return await signInWithEmailAndPassword(this.auth, email, password).then(() => {
      this.redirectSuccessfulLogin();
    });
  }

  async loginWithGoogle() {
    return await signInWithPopup(this.auth, new GoogleAuthProvider()).then(() => {
      this.redirectSuccessfulLogin();
    });
  }

  async loginWithMicrosoft() {
    return signInWithPopup(this.auth, new OAuthProvider('microsoft.com')).then(() => {
      this.redirectSuccessfulLogin();
    });
  }

  async logout() {
    return await signOut(this.auth).then(() => {
      this.router.navigate(['auth/login']);
    });
  }

  redirectSuccessfulLogin() {
    this.router.navigate(['/']);
  }
}
