<div class="container mx-auto py-10 sm:py-14 px-6 text-gray-600">
    <form [formGroup]="songForm">
        <div class="lg:grid lg:grid-cols-7 lg:gap-6">
            <div class="md:col-span-2 px-4 md:px-0">
                <h3 class="text-lg font-semibold uppercase text-chprimary">Basic Song Information</h3>
                <div class="flex items-center">
                    <label class="font-medium mr-2 text-base">Number:</label>
                    <div>
                        <div class="bg-lime-200 flex justify-center py-1 px-2 items-center rounded-md font-medium">{{song?.uid ?? 'Number will be generated upon saving'}}</div>
                    </div>
                </div>
            </div>
            <div class="mt-5 md:col-span-5 md:mt-0 shadow rounded-md bg-white">
                <div class="grid grid-cols-6 gap-6 bg-white rounded-md px-4 py-4 sm:p-6">

                    <div class="col-span-6 sm:col-span-3">
                        <label class="block mb-1 font-medium">Title</label>
                        <input class="w-full"
                               formControlName="title"
                               pInputText
                               required
                               type="text"/>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label class="block mb-1 font-medium">Key</label>
                        <p-dropdown [options]="keyOptions"
                                    [required]="true"
                                    [style]="{'width':'100%'}"
                                    formControlName="key"
                                    optionLabel="name"
                                    optionValue="name"
                                    placeholder="Select a Key"></p-dropdown>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label class="block mb-1 font-medium">Tempo</label>
                            <p-dropdown [options]="tempoOptions"
                                        [required]="false"
                                        [style]="{'width':'100%'}"
                                        formControlName="tempo"
                                        optionLabel="value"
                                        optionValue="value"
                                        placeholder="Select a Tempo"></p-dropdown>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label class="block mb-1 font-medium">Author</label>
                        <input class="w-full"
                               formControlName="author"
                               pInputText
                               type="text"/>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label class="block mb-1 font-medium">Copyright</label>
                        <input class="w-full"
                               formControlName="copyright"
                               pInputText
                               type="text"/>
                    </div>

                </div>

            </div>
        </div>

        <div class="py-5">
            <div class="border-t border-gray-200"></div>
        </div>

        <div class="lg:grid lg:grid-cols-7 lg:gap-6">
            <div class="md:col-span-2 px-4 md:px-0">
                <h3 class="text-lg font-semibold uppercase text-chprimary">Song Lyrics</h3>
            </div>

            <div class="mt-5 md:col-span-5 md:mt-0 shadow rounded-md bg-white">

                <div class="grid grid-cols-6 gap-6 bg-white rounded-md px-4 py-4 sm:p-6">

                    <div class="col-span-6">
                        <div formArrayName="lyrics">


                            <div *ngFor="let lyricItem of lyrics.controls; let i = index">
                                <div [formGroupName]="i"
                                     class="xl:grid xl:grid-cols-3 gap-4 bg-slate-100 rounded-md p-2 my-4">
                                    <div class="mb-2">
                                        <label class="block mb-1 text-sm font-medium">Lyric Type</label>
                                        <p-dropdown [options]="lyricTypes"
                                                    [required]="true"
                                                    [style]="{'width':'100%'}"
                                                    formControlName="type"
                                                    optionLabel="name"
                                                    optionValue="name"
                                                    placeholder="Select a Lyric Type"></p-dropdown>
                                    </div>

                                    <div class="mb-2 col-span-2">
                                        <label class="block mb-1 text-sm font-medium">Content</label>
                                        <textarea [autoResize]="true"
                                                  class="w-full"
                                                  formControlName="content"
                                                  pInputTextarea
                                                  placeholder="Song Verse/Chorus words"
                                                  required
                                                  rows="5"
                                                  type="text"></textarea>
                                    </div>


                                    <div class="xl:mb-2 flex items-end justify-end xl:col-start-3">
                                        <button (click)="removeLyricItem(i)"
                                                class="items-center
                      rounded-md border border-transparent py-2 px-2 text-xs text-white
                      focus:outline-none focus:ring-2 focus:ring-chprimary focus:ring-offset-2 bg-red-400 hover:bg-red-500"
                                                type="button">
                                            Remove
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <button (click)="addLyricItem()"
                                    class="flex items-center mt-4
                      rounded-md border border-transparent py-2 px-4 text-sm text-white
                      focus:outline-none focus:ring-2 focus:ring-chprimary focus:ring-offset-2 bg-chcaribbean hover:bg-chprimary"
                                    type="button">
                                <i class="pi pi-plus pr-2"></i> Add Lyric Item
                            </button>

                        </div>
                    </div>


                </div>
            </div>

        </div>
        <div class="w-full flex place-content-end mt-8">
            <button (click)="onSubmit()"
                    [disabled]="songForm.invalid || isProcessing"
                    [ngClass]="{'bg-slate-400 hover:bg-slate-400': isProcessing || songForm.invalid,
            'bg-chprimary hover:bg-chcaribbean': !isProcessing && !songForm.invalid }"
                    class="rounded-md border
            border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-chprimary focus:ring-offset-2"
                    id="permit-form-submit"
                    type="submit">
                {{isProcessing ? 'Submitting...' : songForm.invalid ? 'Please fill in all required fields' : 'Save Song'}}
            </button>
        </div>
    </form>

    <div *ngIf="isProcessing" class="fixed inset-0 bg-black opacity-50 flex justify-center items-center">
        <p-progressSpinner strokeWidth="4" styleClass="w-12 h-12 text-primary"></p-progressSpinner>
    </div>
</div>