<nav class="lg:left-0 lg:block lg:fixed lg:top-0 lg:bottom-0 lg:overflow-y-auto lg:flex-row
  lg:flex-nowrap lg:overflow-hidden shadow-xl flex flex-wrap items-center
  justify-between lg:text-center relative lg:w-20 z-10 p-2
bg-chprimary lg:bg-white">
    <div
            class="lg:flex-col lg:items-stretch lg:min-h-full lg:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        <!-- Toggler -->
        <button
                (click)="toggleCollapseShow('bg-white m-2 py-3 px-6')"
                class="cursor-pointer text-black lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                type="button">
            <i class="pi pi-bars text-white"></i>
        </button>
        <!-- Brand -->
        <a class="lg:block lg:pb-2 text-white text-sm font-bold p-4 px-0 lg:bg-gradient-to-tr lg:from-orange-400 lg:to-pink-500 lg:bg-clip-text lg:text-transparent"
           routerLink="/">
            choruby
        </a>

        <!-- User -->
        <ul class="lg:hidden items-center flex flex-wrap gap-2 list-none text-gray-50">
            <li class="inline-block relative">
                <a (click)="toggleChords()" [ngStyle]="{'white-space': 'pre-wrap'}"
                   class="flex justify-end text-xs uppercase text-right">
                    {{showChords ? 'Hide \nChords' : 'Show \nChords'}}
                </a>
            </li>
        </ul>
        <!-- Collapse -->
        <div [ngClass]="collapseShow"
             class="lg:flex lg:flex-col lg:items-stretch lg:justify-between lg:opacity-100 lg:relative lg:mt-4 lg:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded">
            <!-- Collapse header -->
            <div class="lg:min-w-full lg:hidden block pb-4 mb-4 border-b border-solid border-gray-200">
                <div class="flex flex-wrap">
                    <div class="w-6/12">
                        <h2 class="lg:block text-left lg:pb-2 mr-0 inline-block whitespace-nowrap font-bold p-4 px-0 bg-gradient-to-tr from-orange-400 to-pink-500 bg-clip-text text-transparent">
                            choruby
                        </h2>
                    </div>
                    <div class="w-6/12 flex justify-end">
                        <button (click)="toggleCollapseShow('hidden')"
                                class="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                type="button">
                            <i class="pi pi-times"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Navigation -->

            <div (click)="toggleCollapseShow('hidden')"
                 class="flex flex-col text-gray-600 text-sm uppercase font-semibold">

                <a *ngIf="(isServiceController$ | async)" [routerLinkActiveOptions]="{ exact: true }"
                   class="hover:text-chprimary p-2 mr-7 lg:mr-0 flex lg:flex-col items-center mb-1 lg:mb-3"
                   routerLink="/dashboard" routerLinkActive="active-link">
                    <svg class="w-8 h-8 mr-1 lg:mr-0"
                         fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                    <span class="lg:hidden">Dashboard</span>
                </a>

                <a class="hover:text-chprimary p-2 mr-7 lg:mr-0 flex lg:flex-col items-center mb-1 lg:mb-3"
                   routerLink="/songs" routerLinkActive="active-link">
                    <svg class="w-8 h-8 mr-1 lg:mr-0" fill="none" stroke="currentColor" stroke-width="1.5"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                    <span class="lg:hidden">Songs</span>
                </a>

                <a class="hover:text-chprimary p-2 mr-7 lg:mr-0 flex lg:flex-col items-center mb-1 lg:mb-3"
                   routerLink="/song-lists" routerLinkActive="active-link">
                    <svg class="w-8 h-8 mr-1 lg:mr-0" fill="none" stroke="currentColor" stroke-width="1.5"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                    <span class="lg:hidden">Song Lists</span>
                </a>
                <a class="hover:text-chprimary p-2 mr-7 lg:mr-0 flex lg:flex-col items-center mb-1 lg:mb-3"
                   routerLink="/play" routerLinkActive="active-link">
                    <svg class="w-8 h-8 mr-1 lg:mr-0" fill="none" stroke="currentColor" stroke-width="1.5"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                    <span class="lg:hidden">Live View</span>
                </a>


            </div>
            <div class="flex flex-col text-gray-600 text-sm uppercase font-semibold">
                <a (click)="reloadPage()"
                   class="hover:text-chprimary cursor-pointer p-2 mr-7 lg:mr-0 flex lg:flex-col items-center mb-1 lg:mb-3"
                   routerLinkActive="active-link">
                    <svg class="w-8 h-8 mr-1 lg:mr-0" fill="none" stroke="currentColor" stroke-width="1.5"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                    <span class="lg:hidden">Force Refresh</span>
                </a>

                <a class="hover:text-chprimary p-2 mr-7 lg:mr-0 flex lg:flex-col items-center mb-1 lg:mb-3"
                   routerLink="/present"
                   routerLinkActive="active-link">
                    <svg class="w-8 h-8 mr-1 lg:mr-0" fill="none" stroke="currentColor" stroke-width="1.5"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                    <span class="lg:hidden">Present Fullscreen</span>
                </a>

                <a (click)="sendMessagePopup()"
                   class="hover:text-chprimary p-2 mr-7 lg:mr-0 flex lg:flex-col items-center mb-1 lg:mb-3"
                   routerLinkActive="active-link">
                    <svg class="w-8 h-8 mr-1 lg:mr-0" fill="none" stroke="currentColor" stroke-width="1.5"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                    <span class="lg:hidden">Send Message</span>
                </a>

                <a (click)="toggleNashville()"
                   class="hover:text-chprimary p-2 mr-7 lg:mr-0 lg:flex hidden lg:flex-col cursor-pointer items-center mb-1 lg:mb-3"
                >
                    {{showNashville ? '1-4-5' : 'C-F-G'}}
                </a>
                <a (click)="toggleChords()"
                   class="hover:text-chprimary p-2 mr-7 lg:mr-0 lg:flex hidden lg:flex-col cursor-pointer items-center mb-1 lg:mb-3"
                >
                    {{showChords ? 'Hide Chords' : 'Show Chords'}}
                </a>
                <a *ngIf="(isAdmin$ | async)" class="hover:text-chprimary p-2 mr-7 lg:mr-0 flex lg:flex-col items-center mb-1 lg:mb-3"
                   routerLink="/admin" routerLinkActive="active-link">
                    <svg class="w-8 h-8 mr-1 lg:mr-0" fill="none" stroke="currentColor" stroke-width="1.5"
                         viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                              stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="lg:hidden">Admin</span>
                </a>
                <a class="hover:text-chprimary p-2 mr-7 lg:mr-0 flex lg:flex-col items-center mb-1 lg:mb-3"
                   routerLink="/profile" routerLinkActive="active-link">
                    <svg class="w-8 h-8 mr-1 lg:mr-0" fill="none" stroke="currentColor" stroke-width="1.5"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                              stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                    <span class="lg:hidden">Profile</span>
                </a>
                <a (click)="logout()"
                   class="hover:text-chprimary p-2 mr-7 lg:mr-0 flex lg:flex-col items-center mb-1 lg:mb-3"
                   routerLinkActive="active-link">
                    <svg class="w-8 h-8 mr-1 lg:mr-0" fill="none" stroke="currentColor" stroke-width="1.5"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                              stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                    <span class="lg:hidden">Logout</span>
                </a>
                <span class="font-normal text-sm lowercase">v1.2.0</span>
            </div>

        </div>
    </div>
</nav>
