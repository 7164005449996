import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core';

@Directive({
    selector: '[appDynamicFontSize]'
})
export class DynamicFontSizeDirective implements AfterViewInit, OnDestroy {
    private observer: MutationObserver;

    @Input('appDynamicFontSize') maxFontSize: string;

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit(): void {
        // Function to resize font
        const resizeFont = () => {
            let fontSize = parseInt(this.maxFontSize) || 150;
            let i = 0;
            this.el.nativeElement.style.fontSize = fontSize + '%';
            while (this.el.nativeElement.scrollHeight > this.el.nativeElement.offsetHeight && i < 50) {
                fontSize = fontSize-10;
                this.el.nativeElement.style.fontSize = (fontSize-10) + '%';
                i++;
            }
        };

        // Initial resize
        resizeFont();

        // Observe for changes in childList and characterData
        const config = {childList: true, characterData: true, subtree: true};

        // Create a callback function for the observer
        const callback = (mutationsList: any, observer: any) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList' || mutation.type === 'characterData') {
                    resizeFont();
                    break;
                }
            }
        };

        // Create an observer instance linked to the callback function
        this.observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        this.observer.observe(this.el.nativeElement, config);
    }

    ngOnDestroy(): void {
        // Disconnect the observer on destroy to prevent memory leaks
        this.observer.disconnect();
    }


}
