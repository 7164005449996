export enum Keys {
    UNKNOWN = "Unknown",
    C_MAJOR = "C",
    C_SHARP_MAJOR = "C#",
    D_FLAT_MAJOR = "Db",
    D_MAJOR = "D",
    D_SHARP_MAJOR = "D#",
    E_FLAT_MAJOR = "Eb",
    E_MAJOR = "E",
    F_MAJOR = "F",
    F_SHARP_MAJOR = "F#",
    G_FLAT_MAJOR = "Gb",
    G_MAJOR = "G",
    G_SHARP_MAJOR = "G#",
    A_FLAT_MAJOR = "Ab",
    A_MAJOR = "A",
    A_SHARP_MAJOR = "A#",
    B_FLAT_MAJOR = "Bb",
    B_MAJOR = "B",
    C_MINOR = "Cm",
    C_SHARP_MINOR = "C#m",
    D_FLAT_MINOR = "Dbm",
    D_MINOR = "Dm",
    D_SHARP_MINOR = "D#m",
    E_FLAT_MINOR = "Ebm",
    E_MINOR = "Em",
    F_MINOR = "Fm",
    F_SHARP_MINOR = "F#m",
    G_FLAT_MINOR = "Gbm",
    G_MINOR = "Gm",
    G_SHARP_MINOR = "G#m",
    A_FLAT_MINOR = "Abm",
    A_MINOR = "Am",
    A_SHARP_MINOR = "A#m",
    B_FLAT_MINOR = "Bbm",
    B_MINOR = "Bm",
}