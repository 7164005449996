import {Injectable} from '@angular/core';
import {
    addDoc,
    arrayUnion,
    collection,
    collectionData,
    CollectionReference,
    doc,
    docData,
    DocumentData,
    Firestore,
    getDoc,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import {Observable} from "rxjs";
import {AuthService} from "./auth.service";
import {AuditLog, CurrentService, Profile, Song, SongList} from "../models";
import {take} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class ChorubyFirestoreService {
    private readonly profileCollection: CollectionReference<DocumentData>;
    private readonly songCollection: CollectionReference<DocumentData>;
    private readonly auditLogCollection: CollectionReference<DocumentData>;
    private readonly songListCollection: CollectionReference<DocumentData>;
    private readonly serviceCollection: CollectionReference<DocumentData>;

    constructor(
        private readonly firestore: Firestore,
        private readonly authService: AuthService,
        // private readonly roles: CurrentUserRolesService,
    ) {
        this.profileCollection = collection(this.firestore, 'profiles');
        this.songCollection = collection(this.firestore, 'songs');
        this.auditLogCollection = collection(this.firestore, 'auditLogs');
        this.songListCollection = collection(this.firestore, 'songLists');
        this.serviceCollection = collection(this.firestore, 'service');
    }

    /**
     * USER & PROFILE RELATED
     */
    getUserEmail() {
        return this.authService.currentUserId;
    }

    getUserProfile$(email?: string) {
        const profileEmail = email ? email : this.getUserEmail();
        return docData(doc(this.firestore, "profiles/", profileEmail)) as Observable<Profile>;
    }

    getUserProfiles$() {
        return collectionData(this.profileCollection, {idField: 'uid'}) as Observable<Profile[]>;
    }

    updateProfile(profile: any, email: string) {
        return setDoc(doc(this.firestore,
            "profiles/",
            email), profile, {merge: true});
    }


    /**
     * SONGS
     */
    getSongs$() {
        return collectionData(this.songCollection, {idField: 'uid'}) as Observable<Song[]>;
    }

    getSong$(uid: string) {
        return docData(doc(this.firestore, "songs/", uid), {idField: 'uid'}) as Observable<Song>;
    }

    updateSong(song: any, uid: string) {
        return setDoc(doc(this.firestore,
            "songs/",
            uid), song, {merge: true});
    }

    createSong(song: any) {
        return addDoc(collection(this.firestore, "songs"), song);
    }

    /**
     * SONG LISTS
     */

    getSongLists$(status: string, userEmail?: string) {
        const statusQuery = query(this.songListCollection, where('status', '==', status));
        if (userEmail) {
            let combinedQuery = query(statusQuery, where('listOwnerEmail', '==', userEmail));
            return collectionData(combinedQuery, {idField: 'uid'}) as Observable<SongList[]>;
        } else {
            return collectionData(statusQuery, {idField: 'uid'}) as Observable<SongList[]>;
        }
    }

    getSongList$(uid: string) {
        return docData(doc(this.firestore, "songLists", uid), {idField: 'uid'}) as Observable<SongList>;
    }

    updateSongList(songList: any, uid: string) {
        return setDoc(doc(this.firestore, "songLists", uid), songList, {merge: true});
    }

    updateSongInListSongs(songs: any, uid: string) {
        return setDoc(doc(this.firestore, "songLists", uid), {songs: songs.songs}, {merge: true});
    }

    createSongList(songList: any) {
        return addDoc(collection(this.firestore, "songLists"), songList);
    }

    setPersonalSelectedSongList(songListId: string) {
        if (songListId) {
            return setDoc(doc(this.firestore, "profiles/", this.getUserEmail()), {selectedSongList: songListId}, {merge: true});
        } else {
            return Promise.reject('Song list id is invalid');
        }
    }

    async addSongToList(songId: string, listId: string) {
        this.getSong$(songId).pipe(take(1)).subscribe(song => {
            return setDoc(doc(this.firestore, "songLists", listId), {
                songs: arrayUnion({
                    uid: song.uid,
                    title: song.title ?? '',
                    key: song.key ?? '',
                })
            }, {mergeFields: ['songs'], merge: true});
        });
    }

    async addCustomItemToSongList(listId: string, item: string) {
        return setDoc(doc(this.firestore, "songLists", listId), {
            songs: arrayUnion({
                title: item,
            })
        }, {mergeFields: ['songs'], merge: true});
    }


    archiveSongList(uid: string) {
        return setDoc(doc(this.firestore, "songLists", uid), {status: 'Archived'}, {merge: true});
    }

    async removeSongFromListByIndex(listId: string, songIndex: number) {
        try {
            // Fetch the song list
            const listSnapshot = await getDoc(doc(this.firestore, "songLists", listId));

            if (listSnapshot.exists()) {
                // Get the songs array from the list document
                const listData = listSnapshot.data() as SongList;
                const songsArray = listData.songs;

                // Check if the song index is within the range of the array
                if (songIndex >= 0 && songIndex < songsArray.length) {
                    // Remove the song at the specified index from the array
                    songsArray.splice(songIndex, 1);

                    // Update the song list in Firestore
                    await updateDoc(doc(this.firestore, "songLists", listId), {songs: songsArray});
                } else {
                    console.log("Song index is out of range");
                }
            } else {
                console.log("List not found");
            }
        } catch (error) {
            console.error("Error removing song from list by index:", error);
        }
    }


    /**
     * CURRENT SERVICE SETTINGS
     * @param songListId
     */
    setGlobalSelectedSongList(songListId: string) {
        return setDoc(doc(this.firestore, "service/", "currentServiceDetails"), {
            list: songListId
        }, {merge: true});
    }

    getCurrentServiceDetails$() {
        return docData(doc(this.firestore, "service/", "currentServiceDetails")) as Observable<CurrentService>;
    }

    setGlobalCurrentSongs(currentSongId: string, currentIndex: number,  key: string) {
        return setDoc(doc(this.firestore, "service/", "currentServiceDetails"), {
            songUid: currentSongId,
            verseIndex: currentIndex,
            key: key
        }, {merge: true});
    }
    blankPresentation(blank: boolean) {
        return setDoc(doc(this.firestore, "service/", "currentServiceDetails"), {
            blank: blank,
        }, {merge: true});
    }

    setGlobalNextVerse(nextSongId: string, nextIndex: number, key: string) {
        return setDoc(doc(this.firestore, "service/", "currentServiceDetails"), {
            nextSongUid: nextSongId,
            nextVerseIndex: nextIndex,
            nextKey: key,
        }, {merge: true});
    }

    setGlobalKey(key: string) {
        return setDoc(doc(this.firestore, "service/", "currentServiceDetails"), {
            key: key
        }, {merge: true});
    }

    setGlobalNextKey(key: string) {
        return setDoc(doc(this.firestore, "service/", "currentServiceDetails"), {
            nextKey: key
        }, {merge: true});
    }



    /**
     * AUDIT LOGS
     */

    getAuditLog$(auditType: string, itemUniqueIdentifier: string) {
        const q = query(this.auditLogCollection,
            orderBy('date', 'desc'),
            where('itemTypeModified', '==', auditType),
            where('itemIdentifier', '==', itemUniqueIdentifier));

        return collectionData(q, {idField: 'uid'}) as Observable<AuditLog[]>;
    }

    setShowChordsPreference(selection: 'Yes' | 'No') {
        const profileEmail = this.getUserEmail();
        return setDoc(doc(this.firestore, "profiles/", profileEmail), {showChords: selection}, {merge: true});
    }
    setShowNashvillePreference(selection: 'Yes' | 'No') {
        const profileEmail = this.getUserEmail();
        return setDoc(doc(this.firestore, "profiles/", profileEmail), {showNashville: selection}, {merge: true});
    }

    verifyProfile(email: string) {
        return setDoc(doc(this.firestore,
            "profiles/",
            email), {
            verified: true,
        }, {merge: true});
    }

    setGlobalTempo(uid: string, tempo: string) {
        return setDoc(doc(this.firestore, "songs/", uid), {tempo: tempo}, {merge: true});
    }
}

