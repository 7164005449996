import {Injectable} from '@angular/core';
import {PermissionOptions} from "../models";
import {BehaviorSubject, map, Observable} from "rxjs";
import {ProfileService} from "./profile.service";

@Injectable({
    providedIn: 'root'
})
export class PermissionControlService {

    private currentUserPermissionRoles: BehaviorSubject<PermissionOptions[]>;
    private _currentUserPermissionRolesString: BehaviorSubject<string>;

    //permissions

    constructor(
        private readonly profile: ProfileService,
    ) {
        this.currentUserPermissionRoles = new BehaviorSubject<PermissionOptions[]>([]);
        this._currentUserPermissionRolesString = new BehaviorSubject<string>('');
        this.getCurrentUserRoles();
    }

    get currentUserPermissionRolesString$(): Observable<string> {
        return this._currentUserPermissionRolesString.asObservable();
    }

    public isAdmin(): Observable<boolean> {
        return this.currentUserPermissionRoles.pipe(
            map(roles => roles?.includes(PermissionOptions.admin)));
    }

    public isServiceController(): Observable<boolean> {
        return this.currentUserPermissionRoles.pipe(
            map(roles => roles?.includes(PermissionOptions.serviceController)));
    }

    public isSongEditor(): Observable<boolean> {
        return this.currentUserPermissionRoles.pipe(
            map(roles => roles?.includes(PermissionOptions.songEditor)));
    }

    public isSongCreator(): Observable<boolean> {
        return this.currentUserPermissionRoles.pipe(
            map(roles => roles?.includes(PermissionOptions.songCreator)));
    }

    public isListEditor(): Observable<boolean> {
        return this.currentUserPermissionRoles.pipe(
            map(roles => roles?.includes(PermissionOptions.listEditor)));
    }

    private getCurrentUserRoles() {
        this.profile.getProfile()
            .subscribe(user => {
                if (user?.permissionRoles) {
                    this.currentUserPermissionRoles.next(user.permissionRoles);
                    this._currentUserPermissionRolesString.next(user.permissionRoles?.join(', '));
                }
            });
    }

}
