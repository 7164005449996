import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Table} from "primeng/table";
import {map, Observable, Subscription} from "rxjs";
import {ChorubyFirestoreService} from "../../core/services/choruby-firestore.service";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {CustomMessageService} from "../../core/services/custom-message.service";
import {CurrentService, Profile, SongList} from "../../core/models";
import {ProfileService} from "../../core/services/profile.service";
import {CurrentSongServiceService} from "../../core/services/current-song-service.service";
import {PermissionControlService} from "../../core/services/permission-control.service";

@Component({
    selector: 'app-song-lists',
    templateUrl: './song-lists.component.html',
    providers: [CustomMessageService]
})
export class SongListsComponent implements OnInit, OnDestroy {
    @ViewChild('songListTable') songListTable: Table;
    protected lists$: Observable<SongList[]>; //todo: replace with Song model
    protected userProfile: Profile | null;

    protected tableColumnNameMap = [
        {field: 'title', header: 'Title'},
        {field: 'date', header: 'Date'},
        {field: 'numberOfSongs', header: '# of Songs'},
        {field: 'listOwnerName', header: 'List Owner'},
        {field: 'notes', header: 'Notes'},
        {header: 'Actions'},
    ];
    protected statusFilterSelection: string = 'Active';
    protected statusFilterOptions: any[] = [
        {name: 'Active', value: 'Active'},
        {name: 'Archived', value: 'Archived'},
    ];

    protected listUserSelection: string = 'Personal';
    protected listUserOptions: any[] = [
        {name: 'Personal', value: 'Personal'},
        {name: 'All', value: 'All'},
    ];

    private subscriptions: Subscription[] = [];
    protected currentService: CurrentService | null;

    constructor(
        private firestore: ChorubyFirestoreService,
        private profileService: ProfileService,
        private router: Router,
        private titleService: Title,
        private messageService: CustomMessageService,
        private currentSongService: CurrentSongServiceService,
        private userRoles: PermissionControlService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.titleService.setTitle('Song Lists - Choruby');
        this.subscriptions.push(this.profileService.getProfile().subscribe(async (userProfile) => {
            this.userProfile = await userProfile;
            await this.getLists();
        }));
        await this.getCurrentService();
    }

    private async getCurrentService() {
        this.subscriptions.push(
            await this.currentSongService.getCurrentService().subscribe(service => {
                this.currentService = service;
            })
        );
    }

    async getLists() {
        const userSelection = this.listUserSelection === 'Personal' ? this.userProfile?.email : '';

        this.lists$ = this.firestore.getSongLists$(this.statusFilterSelection, userSelection).pipe(
            map((lists: SongList[]) => {
                return lists.map(list => {
                    const numberOfSongs = list.songs ? list.songs.length : 0;
                    return {
                        ...list,
                        numberOfSongs: numberOfSongs, // Add the count of songs
                    };
                });
            })
        );
    }

    applyFilterGlobal($event: any) {
        this.songListTable.filterGlobal(($event.target as HTMLInputElement).value, 'contains');
    }

    clear(table: Table) {
        table.clear();
    }

    activateSongList(songListId: string, songListTitle: string) {
        this.firestore.setPersonalSelectedSongList(songListId).then(() => {
            this.messageService.showSuccess('Song List Activated', 'Newly added songs will now be added to this selected list');
        }).catch((error) => {
            this.messageService.showError('Error Activating Song List', error);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription?.unsubscribe());
    }

    protected async onFilterChange() {
        await this.getLists();
    }

    protected activateAsGlobalList(uid: string) {
        this.firestore.setGlobalSelectedSongList(uid).then(() => {
            this.messageService.showSuccess('Activated', 'Song list activated as main service list');
        }).catch((error) => {
            this.messageService.showError('Could not activate', error);
        });


    }
}