import { Injectable } from '@angular/core';
import {Song} from "../models";

@Injectable({
  providedIn: 'root'
})
export class SongControlsService {

  constructor() { }

  public updateVerseNumberForSong(song: Song) {
    let verseCountForSong: number[] = [];
    let count = 0;
    song.lyrics.forEach((lyric, index) => {
      if (lyric.type === 'Chorus') {
        count++;
      }
      verseCountForSong[index] = count;
    });
    return verseCountForSong;
  }

  /**
   * Returns an array of song order, replicating chorus after each verse,
   * only after the chorus appears in the song.
   * @param song
   */
  public getVerseOrderIndex(song: Song): number[] {
    let verseOrderIndex: number[] = [];
    let chorusIndex = -1;

    song.lyrics.forEach((lyric, index) => {
      if (lyric.type === 'Chorus') {
        chorusIndex = index;
      }
      verseOrderIndex.push(index);
      if (chorusIndex !== -1 && lyric.type !== 'Chorus') {
        verseOrderIndex.push(chorusIndex);
      }
    });
    return verseOrderIndex;
  }

  public duplicateVersesInOrder(order: number[], song: Song): Song['lyrics'] {
    let duplicatedLyrics: Song['lyrics'] = [];
    order.forEach(index => {
      duplicatedLyrics.push(song.lyrics[index]);
    });
    return duplicatedLyrics;
  }

}
