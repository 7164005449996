import {Routes} from '@angular/router';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {ProfileComponent} from "./profile/profile.component";
import {profileCompletedAndVerifiedGuard} from "../core/guards/profile-completed-and-verified.guard";
import {AdminComponent} from "./admin/admin.component";
import {adminGuard} from "../core/guards/admin.guard";
import {UserManagementComponent} from "./admin/user-management/user-management.component";
import {SongsComponent} from "./songs/songs.component";
import {SongViewComponent} from "./songs/song-view/song-view.component";
import {SongEditComponent} from "./songs/song-edit/song-edit.component";
import {SongListsComponent} from "./song-lists/song-lists.component";
import {ListEditComponent} from "./song-lists/list-edit/list-edit.component";
import {ListViewComponent} from "./song-lists/list-view/list-view.component";
import {PlayComponent} from "./play/play.component";
import {PresenterComponent} from "./presenter/presenter.component";
import {BookViewComponent} from "./songs/book-view/book-view.component";
import {serviceControllerGuard} from "../core/guards/service-controller.guard";

const Routing: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [profileCompletedAndVerifiedGuard, serviceControllerGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [profileCompletedAndVerifiedGuard, adminGuard],
  },
  {
    path: 'admin/user-management',
    component: UserManagementComponent,
    canActivate: [profileCompletedAndVerifiedGuard, adminGuard],
  },
  {
    path: 'songs',
    component: SongsComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'songs/book',
    component: BookViewComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'song/new',
    component: SongEditComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'song/:songId/edit',
    component: SongEditComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'song/:songId/view',
    component: SongViewComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'song-lists',
    component: SongListsComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'song-list/new',
    component: ListEditComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'song-list/:listId/view',
    component: ListViewComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'song-list/:listId/edit',
    component: ListEditComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'play',
    component: PlayComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'present',
    component: PresenterComponent,
    canActivate: [profileCompletedAndVerifiedGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'profile/:email',
    component: ProfileComponent,
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'play',
  },
];

export {Routing};
