<div class="lg:h-screen h-[calc(100vh-60px)] select-none grid grid-cols-4 grid-rows-5 gap-4 p-2 xl:p-5 text-gray-800 grid-flow-dense">

    <!--PRESENTED VERSE-->
    <div class="bg-white p-2 col-span-4 row-span-3 grid grid-cols-1 grid-rows-1 rounded">
        <ng-container *ngIf="currentSong as song; else noCurrentSong">
            <div class="col-start-1 row-start-1 flex justify-between gap-2">
                <h2 class="uppercase text-sm text-gray-500 font-medium">Presented Verse</h2>
                <h2 class="text-base text-chprimary font-medium">{{currentSong?.uid}} - {{currentSong?.title}}</h2>
            </div>

            <div class="col-start-1 row-start-1 flex justify-between items-end gap-2">
                <div class="text-gray-500">
                    {{currentSongTransformedLyrics[currentVerseIndex]?.type !== 'Chorus' && currentSongTransformedLyrics[currentVerseIndex]?.type
                    ? currentSongTransformedLyrics[currentVerseIndex]?.type + ' - ' + (currentVerseIndex + 1 - (verseCountForCurrentSong[currentVerseIndex] ?? 0)) :
                    currentSongTransformedLyrics[currentVerseIndex]?.type ?? ''}}
                </div>
                <div class="text-red-500 font-medium">
                    {{currentVerseKey}}
                </div>
            </div>

            <div appDynamicFontSize="180" class="col-start-1 row-start-1 justify-center items-center text-center text-2xl flex">
                <div class="relative" id="lyricContentDiv">
                   <p #lyricsContent
                      [innerHTML]="parseLyricsContent((currentSongTransformedLyrics[currentVerseIndex])?.content ?? '(blank)',  currentVerseKey)"
                      [ngClass]="{'leading-chords': showChords}"
                      [ngStyle]="{'white-space': 'pre-wrap'}" class="leading-loose h-full overflow-auto pt-10 pb-8"></p>

                </div>
            </div>

        </ng-container>
        <ng-template #noCurrentSong>
            <h2 class="uppercase text-sm text-gray-500 font-medium"></h2>
        </ng-template>
    </div>


    <div class="p-2 bg-white col-span-4 row-span-2 grid grid-cols-1 grid-rows-1 rounded">
        <ng-container *ngIf="nextVerseSong as song; else noNextVerseSong">

            <div class="col-start-1 row-start-1 flex justify-between gap-2">
                <h2 class="uppercase text-sm text-gray-500 font-medium">Next Verse</h2>

                <h2 class="text-sm text-chprimary font-medium">{{song?.uid}} - {{song?.title}}</h2>

            </div>

            <div class="col-start-1 row-start-1 flex justify-between items-end gap-2">
                <div class="text-gray-500">
                    {{nextSongTransformedLyrics[nextVerseIndex]?.type !== 'Chorus' && nextSongTransformedLyrics[nextVerseIndex]?.type
                    ? nextSongTransformedLyrics[nextVerseIndex]?.type + ' - ' + (nextVerseIndex + 1 - (verseCountForNextSong[nextVerseIndex] ?? 0)) :
                    nextSongTransformedLyrics[nextVerseIndex]?.type ?? ''}}
                </div>
                <div class="text-red-500 font-medium"
                     [ngClass]="nextVerseKey !== currentVerseKey ? 'text-3xl font-semibold animate-bounce' : 'text-base'">
                    {{nextVerseKey}}
                </div>
            </div>

            <div appDynamicFontSize="130" class="col-start-1 row-start-1 justify-center items-center text-center text-lg flex">
                <div class="relative">
                    <p [innerHTML]="parseLyricsContent((nextSongTransformedLyrics[nextVerseIndex])?.content ?? '(blank)', nextVerseKey)"
                       [ngClass]="{'leading-chords': showChords}"
                       [ngStyle]="{'white-space': 'pre-wrap'}" class="leading-loose pt-4"></p>
                </div>
            </div>
        </ng-container>
        <ng-template #noNextVerseSong>
            <h2 class="uppercase text-sm text-chprimary font-medium">No Current Song</h2>
        </ng-template>

    </div>
</div>

<div class="select-none p-2 xl:p-5 text-gray-800 ">
    <div class="p-2">
        <ng-container *ngIf="currentSong as song; else noSelectedSong">
            <h2 class="uppercase text-base mb-2 text-chprimary font-medium">{{song?.uid}} - {{song?.title ?? 'No Selected Song'}}
                -
                verses</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <div *ngFor="let lyric of currentSongTransformedLyrics; let i=index"
                     [ngClass]="((song.uid === currentSong?.uid) && (currentVerseIndex === i)) ? 'bg-lime-50' :
                      ((song.uid === nextVerseSong?.uid) && (nextVerseIndex === i)) ? 'bg-fuchsia-50' : '' "
                     class="bg-white p-4 shadow rounded-md border">

                    <h3 class="text-sm font-medium uppercase text-gray-500">
                        {{lyric.type !== 'Chorus' ? lyric.type + ' - ' + (i + 1 - (verseCountForCurrentSong[i] ?? 0)) : lyric.type}}
                    </h3>
                    <div class="border-t border-gray-300 mb-5 mt-1"></div>
                    <div class="relative">
                        <p [innerHTML]="parseLyricsContent(lyric.content, song?.key)" [ngClass]="{'leading-chords': showChords}"
                           [ngStyle]="{'white-space': 'pre-wrap'}" class="leading-loose"></p>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noSelectedSong>
            <h2 class="uppercase text-sm text-gray-500 font-medium">No Verses</h2>
        </ng-template>
    </div>
</div>