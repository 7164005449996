import { Component } from '@angular/core';
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  providers: [MessageService]

})
export class LayoutComponent {

}
