<div (click)="launchFullScreen('fullscreenDiv')" id="fullscreenDiv" class="bg-black capitalize h-screen text-white p-2 col-span-4 row-span-3 grid grid-cols-1 grid-rows-1 rounded">
    <ng-container *ngIf="!blankPresentation && currentSong as song; else noCurrentSong">

        <div *ngIf="currentVerseIndex !== -1 && currentVerseIndex < currentSongTransformedLyrics.length" class="col-start-1 row-start-1 flex flex-col justify-end gap-2 text-sm text-gray-400">
            <div>
                Copyright: {{currentSong.copyright}}
            </div>
            <div>
                Author: {{currentSong.author}}
            </div>
        </div>

        <div appDynamicFontSize="600" class="col-start-1 row-start-1 justify-center items-center text-center text-2xl flex">
            <div class="relative" id="lyricContentDiv">
                <p #lyricsContent
                   [innerHTML]="parseLyricsContent((currentSongTransformedLyrics[currentVerseIndex])?.content ?? '')"
                   [ngStyle]="{'white-space': 'pre-wrap'}" class="leading-snug h-full overflow-auto py-10 font-semibold"></p>
            </div>
        </div>

    </ng-container>
    <ng-template #noCurrentSong>
        <h2 class="uppercase text-sm text-gray-500 font-medium"></h2>
    </ng-template>
</div>