<section class="mx-auto p-5 pb-14">
    <div class="grid grid-cols-3 md:grid-cols-1 grid-flow-dense gap-2">
        <div>
            <button class="mb-5 rounded-md border bg-chprimary hover:bg-chcaribbean
            border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
                    routerLink="/song-list/new">
                New Song List
            </button>
        </div>
        <div class="mb-2 md:col-start-2 col-start-1 col-end-4 md:col-end-4 row-start-1 flex items-center md:justify-end justify-center gap-6">
            <p-selectButton (onChange)="onFilterChange()"
                            [(ngModel)]="listUserSelection"
                            [multiple]="false"
                            [options]="listUserOptions"
                            optionLabel="name"
                            optionValue="value"></p-selectButton>

            <p-selectButton (onChange)="onFilterChange()"
                            [(ngModel)]="statusFilterSelection"
                            [multiple]="false"
                            [options]="statusFilterOptions"
                            optionLabel="name"
                            optionValue="value"></p-selectButton>
        </div>
    </div>

    <p-table #songListTable
             [globalFilterFields]="['title', 'date', 'numberOfSongs', 'listOwnerName', 'notes']"
             [paginator]="true"
             [rowsPerPageOptions]="[25,50,100,200]"
             [rows]="25"
             [showCurrentPageReport]="true"
             [sortField]="'date'"
             [sortOrder]="-1"
             [value]="(lists$ | async) ?? []"
             styleClass="p-datatable-striped text-sm shadow-md p-datatable-gridlines p-datatable-sm">

        <ng-template pTemplate="caption">
            <div class="flex">

                <button (click)="clear(songListTable)"
                        class="p-button-outlined"
                        icon="pi pi-filter-slash"
                        label="Clear"
                        pButton></button>

                <span class="p-input-icon-left ml-auto w-56 md:w-96">
                    <i class="pi pi-search"></i>
                    <input (input)="applyFilterGlobal($event)"
                           class="w-full"
                           pInputText
                           placeholder="Search keyword"
                           type="text"/>
        </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>

                <!--TITLE-->
                <th class="hidden md:table-cell">
                    <p-columnFilter [showClearButton]="false"
                                    [showMenu]="false"
                                    field="title"
                                    matchMode="contains"
                                    type="text"></p-columnFilter>
                </th>

                <!--DATE-->
                <th class="hidden md:table-cell">
                    <p-columnFilter [showClearButton]="false"
                                    [showMenu]="false"
                                    dateFormat="dd/mm/yy"
                                    field="date"
                                    matchMode="contains"
                                    type="date"></p-columnFilter>
                </th>

                <!--KEY-->
                <th class="hidden md:table-cell">
                    <p-columnFilter [showClearButton]="false"
                                    [showMenu]="false"
                                    field="numberOfSongs"
                                    matchMode="contains"
                                    type="text"></p-columnFilter>
                </th>

                <!--OWNER-->
                <th class="hidden md:table-cell">
                    <p-columnFilter [showClearButton]="false"
                                    [showMenu]="false"
                                    field="listOwnerName"
                                    matchMode="contains"
                                    type="text"></p-columnFilter>
                </th>

                <!--NOTES-->
                <th class="md:table-cell hidden">
                    <p-columnFilter [showClearButton]="false"
                                    [showMenu]="false"
                                    field="notes"
                                    matchMode="contains"
                                    type="text"></p-columnFilter>
                </th>

                <!--ACTIONS-->
                <th class="hidden md:table-cell">
                </th>


            </tr>
            <tr>
                <!-- Column Headers -->
                <ng-container *ngFor="let colDef of tableColumnNameMap">
                    <th *ngIf="colDef.field; else actionsHeader"
                        [ngClass]="{'md:table-cell': colDef.header === 'Notes', 'hidden': colDef.header === 'Notes'}"
                        pSortableColumn="{{colDef.field}}">
                        {{colDef.header}}
                        <div class="hidden lg:inline">
                            <p-sortIcon field="{{colDef.field}}"></p-sortIcon>
                        </div>
                    </th>
                    <ng-template #actionsHeader>
                        <th>{{colDef.header}}</th>
                    </ng-template>
                </ng-container>
            </tr>
        </ng-template>

        <ng-template let-list pTemplate="body">
            <tr>

                <td>
                    <div class="font-medium lg:text-base text-chprimary"
                         routerLink="/song-list/{{list?.uid}}/view">{{list?.title }}</div>
                </td>

                <td>
                    <div class="text-center">{{list?.date != '' ? (list?.date.toDate()  | date:'EE dd MMM yyyy') : ''}}</div>
                </td>

                <td>
                    <div class="text-center">{{list?.numberOfSongs }}</div>
                </td>

                <td>
                    <div class="">{{list?.listOwnerName }}</div>
                </td>

                <td class="md:table-cell hidden">
                    <div>{{list?.notes }}</div>
                </td>

                <td class="flex gap-2">
                    <button class="md:inline-block hidden rounded-md border bg-chprimary hover:bg-chcaribbean
                        border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
                            routerLink="/song-list/{{list?.uid}}/view">
                        View
                    </button>

                    <button (click)="activateSongList(list?.uid, list?.title)"
                            [ngClass]="userProfile?.selectedSongList === list?.uid ? 'bg-chgforest' : 'bg-gray-400'"
                            class="flex justify-center items-center rounded-md border hover:bg-chcaribbean
                        border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                        <i class="pi pi-check"></i>
                    </button>
                    <button (click)="activateAsGlobalList(list?.uid)"
                            [ngClass]="currentService?.list === list?.uid ? 'bg-chgforest' : 'bg-gray-400'"
                            class="md:inline-block hidden flex justify-center items-center rounded-md border hover:bg-chcaribbean
                        border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                        <svg class="w-6 h-6"
                             fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </button>
            </tr>
        </ng-template>
    </p-table>


</section>
