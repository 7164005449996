import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CustomMessageService} from "../../../core/services/custom-message.service";
import {ChorubyFirestoreService} from "../../../core/services/choruby-firestore.service";
import {CurrentService, Profile, SongList} from "../../../core/models";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {ProfileService} from "../../../core/services/profile.service";
import {Table} from "primeng/table";
import {CurrentSongServiceService} from "../../../core/services/current-song-service.service";
import {PermissionControlService} from "../../../core/services/permission-control.service";

@Component({
    selector: 'app-list-view',
    templateUrl: './list-view.component.html',
    providers: [CustomMessageService]
})
export class ListViewComponent implements OnInit, OnDestroy {
    @ViewChild('songsTable') songsTable: Table;
    protected songList: SongList;
    protected currentService: CurrentService | null;
    protected userProfile: Profile | null;
    private subscriptions: Subscription[] = [];


    constructor(
        private messageService: CustomMessageService,
        private firestore: ChorubyFirestoreService,
        private titleService: Title,
        private route: ActivatedRoute,
        private router: Router,
        private profileService: ProfileService,
        private currentSongService: CurrentSongServiceService,
        private userRoles: PermissionControlService,
    ) {
    }

    async ngOnInit() {
        this.titleService.setTitle('List View - Choruby');
        const listId = this.route.snapshot.paramMap.get('listId');
        await this.getSongList(listId ?? '');
        await this.getUserProfile();
        await this.getCurrentService();
    }

    protected setGlobalList(){
        this.firestore.setGlobalSelectedSongList(this.songList.uid).then(() => {
            this.messageService.showSuccess('Activated', 'Song list activated as main service list');
        }).catch((error) => {
            this.messageService.showError('Could not activate', error);
        });
    }


    async getUserProfile() {
        this.profileService.getProfile().subscribe((userProfile) => {
            this.userProfile = userProfile;
        });
    }

    private async getCurrentService() {
        this.subscriptions.push(
            await this.currentSongService.getCurrentService().subscribe(service => {
                this.currentService = service;
            })
        );
    }

    async getSongList(listId: string) {
        this.subscriptions.push(
            await this.firestore.getSongList$(listId).subscribe(list => {
                this.songList = list;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s?.unsubscribe());
    }


    deleteList() {
        this.firestore.archiveSongList(this.songList.uid).then(() => {
            this.messageService.showInfo('Archived', 'List archived');
            this.router.navigate(['/song-lists']);
        }).catch((error) => {
            this.messageService.showError('Could not delete', error);
        });
    }

    activateSongList() {
        this.firestore.setPersonalSelectedSongList(this.songList.uid).then(() => {
            this.messageService.showSuccess('Song List Activated', 'Newly added songs will now be added to this selected list');
        }).catch((error) => {
            this.messageService.showError('Error Activating Song List', error);
        });
    }

    async removeSongByIndex(listId: string, songIndex: number) {
        if (songIndex !== -1) {
            await this.firestore.removeSongFromListByIndex(listId, songIndex).catch((error) => {
                this.messageService.showError('Error Removing Song', error);
            });
        }
    }


    async onListReorder(event: any) {
        this.songList.songs.forEach((song, index) => {
        });
        await this.firestore.updateSongInListSongs({songs: this.songList.songs}, this.songList.uid);
    }

    protected addCustomItem(customItemInput: HTMLInputElement) {
        if(!customItemInput.value.trim()) {
            // It's empty or just whitespace - do nothing
            return;
        }

        this.firestore.addCustomItemToSongList(this.songList.uid, customItemInput.value).then(() => {
            this.messageService.showSuccess('Success', 'Custom item added');
            customItemInput.value = ''; // clear the input
        }).catch((error) => {
            this.messageService.showError('Error', "Couldn't add custom item to the list" + error);
        });
    }

}
