import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LayoutComponent} from './layout.component';
import {FooterComponent} from "./footer/footer.component";
import {HeaderComponent} from "./header/header.component";
import {UserDropdownComponent} from "./user-dropdown/user-dropdown.component";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {TooltipModule} from "primeng/tooltip";
import {BadgeModule} from "primeng/badge";
import {Routing} from "../pages/internal-routing";
import {RouterModule, Routes} from "@angular/router";
import {ToastModule} from "primeng/toast";
import {ButtonModule} from "primeng/button";
import {SelectButtonModule} from "primeng/selectbutton";
import {ProfileComponent} from "../pages/profile/profile.component";
import {DashboardComponent} from "../pages/dashboard/dashboard.component";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {TableModule} from "primeng/table";
import {AdminComponent} from "../pages/admin/admin.component";
import {MultiSelectModule} from "primeng/multiselect";
import {InputTextareaModule} from "primeng/inputtextarea";
import {CalendarModule} from "primeng/calendar";
import {KeyFilterModule} from "primeng/keyfilter";
import {RadioButtonModule} from "primeng/radiobutton";
import {AutoCompleteModule} from "primeng/autocomplete";
import {TreeSelectModule} from "primeng/treeselect";
import {CheckboxModule} from "primeng/checkbox";
import {UserManagementComponent} from "../pages/admin/user-management/user-management.component";
import {SongsComponent} from "../pages/songs/songs.component";
import {SongViewComponent} from "../pages/songs/song-view/song-view.component";
import {SongEditComponent} from "../pages/songs/song-edit/song-edit.component";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ChordsLyricsParserPipe} from "../core/services/chord-mgmt/chords-lyrics-parser.pipe";
import {RemoveChordsParserPipe} from "../core/services/chord-mgmt/remove-chords-parser.pipe";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {SongListsComponent} from "../pages/song-lists/song-lists.component";
import {ListViewComponent} from "../pages/song-lists/list-view/list-view.component";
import {ListEditComponent} from "../pages/song-lists/list-edit/list-edit.component";
import {InputNumberModule} from "primeng/inputnumber";
import {OrderListModule} from "primeng/orderlist";
import {ListboxModule} from "primeng/listbox";
import {PlayComponent} from "../pages/play/play.component";
import {DynamicFontSizeDirective} from "../core/services/dynamic-font-size.directive";
import {PresenterComponent} from "../pages/presenter/presenter.component";
import {BookViewComponent} from "../pages/songs/book-view/book-view.component";

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: Routing,
    }
];

@NgModule({
    declarations: [
        LayoutComponent,
        FooterComponent,
        HeaderComponent,
        UserDropdownComponent,
        SidebarComponent,
        DashboardComponent,
        AdminComponent,
        ProfileComponent,
        UserManagementComponent,
        SongsComponent,
        SongViewComponent,
        SongEditComponent,
        ChordsLyricsParserPipe,
        RemoveChordsParserPipe,
        SongListsComponent,
        ListViewComponent,
        ListEditComponent,
        PlayComponent,
        DynamicFontSizeDirective,
        PresenterComponent,
        BookViewComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        TooltipModule,
        BadgeModule,
        ToastModule,
        SelectButtonModule,
        ButtonModule,
        InputTextModule,
        ReactiveFormsModule,
        DropdownModule,
        FormsModule,
        TableModule,
        MultiSelectModule,
        InputTextareaModule,
        CalendarModule,
        KeyFilterModule,
        RadioButtonModule,
        AutoCompleteModule,
        TreeSelectModule,
        CheckboxModule,
        SelectButtonModule,
        TableModule,
        ProgressSpinnerModule,
        OverlayPanelModule,
        InputNumberModule,
        OrderListModule,
        ListboxModule,

    ],
    exports: [
        DynamicFontSizeDirective,
    ],
})
export class LayoutModule {
}
