<div class="container mx-auto py-10 sm:py-14 px-6 text-gray-600">
    <form [formGroup]="listForm">
        <div class="lg:grid lg:grid-cols-7 lg:gap-6">
            <div class="md:col-span-2 px-4 md:px-0">
                <h3 class="text-lg font-semibold uppercase text-chprimary">Song List Information</h3>
            </div>
            <div class="mt-5 md:col-span-5 md:mt-0 shadow rounded-md bg-white">
                <div class="grid grid-cols-6 gap-6 bg-white rounded-md px-4 py-4 sm:p-6">

                    <div class="col-span-6 sm:col-span-3">
                        <label class="block mb-1 font-medium">List Type</label>
                        <p-dropdown [options]="listOptions"
                                    [required]="false"
                                    [style]="{'width':'100%'}"
                                    formControlName="title"
                                    optionLabel="value"
                                    optionValue="value"
                                    placeholder="Select a List Type"></p-dropdown>
<!--                        <input class="w-full"-->
<!--                               formControlName="title"-->
<!--                               pInputText-->
<!--                               required-->
<!--                               type="text"/>-->
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label class="block mb-1 font-medium">Date of Usage</label>
                        <p-calendar [required]="true"
                                    [showIcon]="true"
                                    dateFormat="dd/mm/yy"
                                    formControlName="date"
                                    styleClass="w-full"></p-calendar>
                        <span class="text-sm text-gray-500">Don't know the exact date? Select an estimated date and update it later.</span>
                    </div>

<!--                    <div class="col-span-6">-->
<!--                        <label class="block mb-1 font-medium">Notes</label>-->
<!--                        <textarea [autoResize]="true"-->
<!--                                  class="w-full"-->
<!--                                  formControlName="notes"-->
<!--                                  pInputTextarea-->
<!--                                  placeholder="Notes are seen by yourself, the tech desk and musicians."-->
<!--                                  ></textarea>-->
<!--                    </div>-->
                </div>
            </div>
        </div>

        <div class="w-full flex place-content-end mt-8">
            <button (click)="onSubmit()"
                    [disabled]="listForm.invalid || isProcessing"
                    [ngClass]="{'bg-slate-400 hover:bg-slate-400': isProcessing || listForm.invalid,
            'bg-chprimary hover:bg-chcaribbean': !isProcessing && !listForm.invalid }"
                    class="rounded-md border
            border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-chprimary focus:ring-offset-2"
                    id="permit-form-submit"
                    type="submit">
                {{isProcessing ? 'Submitting...' : listForm.invalid ? 'Please fill in all required fields' : 'Save Song List'}}
            </button>
        </div>
    </form>

    <div *ngIf="isProcessing" class="fixed inset-0 bg-black opacity-50 flex justify-center items-center">
        <p-progressSpinner strokeWidth="4" styleClass="w-12 h-12 text-primary"></p-progressSpinner>
    </div>
</div>