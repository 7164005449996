import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {delay, Observable, of, Subscription, switchMap, timeout} from "rxjs";
import {PermissionOptions, Profile, yesNoSelector} from "../../core/models";
import {ChorubyFirestoreService} from "../../core/services/choruby-firestore.service";
import {CustomMessageService} from "../../core/services/custom-message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionControlService} from "../../core/services/permission-control.service";
import {Title} from "@angular/platform-browser";
import {} from "../../core/models/yesNoSelector";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  providers: [CustomMessageService],
})
export class ProfileComponent implements OnInit, OnDestroy {

  protected isProcessing: boolean;
  protected profileForm: FormGroup;
  private requestedProfileEmail: string;
  private profile$: Observable<Profile>;
  protected displayEmail: string;
  private federatedProvider: string;
  protected isAdmin$: Observable<boolean>;
  protected currentUserPermissionRoles$: Observable<string>;
  protected permissionOptions = Object.values(PermissionOptions).map(key => ({name: key}));
  protected yesNoPreferences = Object.values(yesNoSelector).map(key => ({option: key}));
  protected profileVerifiedBgColor: string = 'bg-slate-200';
  protected profileCompleteBgColor: string = 'bg-slate-200';
  private statusColors: any = {
    1: 'bg-emerald-500 text-white',
    0: 'bg-amber-200'
  };
  protected verified: string = 'Not Verified';
  protected completed: string = 'Not Completed';

  private subscriptions: Subscription[] = [];

  constructor(
      private fb: FormBuilder,
      private firestore: ChorubyFirestoreService,
      private currentUserRolesService: PermissionControlService,
      private messageService: CustomMessageService,
      private route: ActivatedRoute,
      private titleService: Title,
      private router: Router,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle('Profile');
    this.isAdmin$ = this.currentUserRolesService.isAdmin();
    this.currentUserPermissionRoles$ = this.currentUserRolesService.currentUserPermissionRolesString$;

    await this.buildForm();

    this.subscriptions.push(this.route.params.subscribe(params => {
      if (params['email']) {
        this.requestedProfileEmail = params['email'];
      }
      this.getProfile();
    }));


    this.subscriptions.push(
        this.profile$.pipe(
            switchMap(p => p ? of(p) : of(p).pipe(delay(3000))), // Delay only if p is falsy
            timeout(10000) // Timeout after 10 seconds
        ).subscribe(p => {
          if (!p) {
            this.messageService.showError('Error', 'There was an error loading the profile, please try again. If the problem persist please get in touch with Tech Support.');
            return;
          }

          this.verified = p.verified ? 'Verified' : 'Not Verified';
          console.log(p.verified, 'verified');
          this.profileVerifiedBgColor = this.statusColors[p.verified ? 1 : 0];
          this.completed = p.profileCompleted ? 'Completed' : 'Not Completed';
          this.profileCompleteBgColor = this.statusColors[p.profileCompleted ? 1 : 0];

          console.log(this.profileCompleteBgColor, 'profileCompleteBgColor');
          this.profileForm.patchValue(p as Profile);
          this.profileForm.updateValueAndValidity({onlySelf: true, emitEvent: true});
          this.displayEmail = p.email;
          this.federatedProvider = p.provider;

          if (!p.profileCompleted) {
            this.messageService.showWarn('Complete your profile', 'Please complete your profile before you can use the app.');
          }

          if (!p.verified) {
            this.messageService.showWarn('Verification Required', 'An admin user will verify your account before you can use the app.');
          }
        }));
  }

  async getProfile() {
    this.profile$ = this.firestore.getUserProfile$(this.requestedProfileEmail);
  }

  async buildForm() {
    this.profileForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.pattern('[- +()0-9]{6,}')]],
      permissionRoles: [null],
      showChords: [null, [Validators.required]],
      showNashville: [null, [Validators.required]],
    });
  }

  onSubmit() {
    this.isProcessing = true;
    if (this.profileForm.valid) {
      this.firestore.updateProfile({
        ...this.profileForm.getRawValue(),
        profileCompleted: true
      }, this.displayEmail).then(() => {
        if (this.isAdmin$ && this.requestedProfileEmail) {
          this.router.navigate(['/admin/user-management']).then(() => {
            this.messageService.showSuccess('Success', 'User profile updated.')
          })
        } else if (this.verified !== 'Not Verified') {
          this.router.navigate(['/songs']).then(() => {
            this.messageService.showSuccess('Success', 'Your profile has been updated.');
          });
        }
      }).catch(() => {
        this.messageService.showError('Error', 'There was an error updating your profile.');
      }).finally(() => {
        this.isProcessing = false;
      });
    } else {
      this.messageService.showWarn('Error', 'Please check your form for errors.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb?.unsubscribe());
  }
}
