import {Component, OnDestroy, OnInit} from '@angular/core';
import {Song} from "../../core/models";
import {RemoveChordsParserPipe} from "../../core/services/chord-mgmt/remove-chords-parser.pipe";
import {Subject, Subscription} from "rxjs";
import {ChorubyFirestoreService} from "../../core/services/choruby-firestore.service";
import {CurrentSongServiceService} from "../../core/services/current-song-service.service";
import {Title} from "@angular/platform-browser";
import {SongControlsService} from "../../core/services/song-controls.service";

@Component({
    selector: 'app-presenter',
    templateUrl: './presenter.component.html',
})
export class PresenterComponent implements OnInit, OnDestroy {
    protected currentSong: Song | null;
    protected currentVerseIndex: number;


    protected currentSongTransformedLyrics: Song['lyrics'];

    protected removeChordsParserPipe: RemoveChordsParserPipe;
    private subscriptions: Subscription[] = [];

    private unsubscribe$ = new Subject<void>();
    protected verseCountForCurrentSong: number[] = [];
    private songVerseOrder: number[];
    protected blankPresentation: boolean;

    constructor(
        private firestore: ChorubyFirestoreService,
        private currentSongService: CurrentSongServiceService,
        private titleService: Title,
        private songControls: SongControlsService,
    ) {
        this.removeChordsParserPipe = new RemoveChordsParserPipe();
    }

    async ngOnInit(): Promise<void> {
        this.titleService.setTitle('Choruby - Presentation');

        this.subscriptions.push(
            this.currentSongService.getCurrentSong().subscribe((song) => {
                    this.currentSong = song;
                    if (song && this.currentSong) {
                        this.songVerseOrder = this.songControls.getVerseOrderIndex(song);
                        this.currentSongTransformedLyrics = this.songControls.duplicateVersesInOrder(this.songVerseOrder, this.currentSong);
                        this.verseCountForCurrentSong = this.songControls.updateVerseNumberForSong(song);
                    }
                }
            ));

        this.subscriptions.push(
            this.currentSongService.getCurrentVerseIndex().subscribe((index) => {
                this.currentVerseIndex = index;
            })
        );
        this.subscriptions.push(
            this.currentSongService.getBlankPresentation().subscribe((blank) => {
                this.blankPresentation = blank;
                console.log('blankx', blank);
            })
        );
    }

    protected parseLyricsContent(content: string): string {
        return this.removeChordsParserPipe.transform(content);
    }

    launchFullScreen(elementId: string) {
        const element = document.getElementById(elementId);

        if (element?.requestFullscreen) {
            element.requestFullscreen();
        } else if ((element as any).mozRequestFullScreen) { // Firefox
            (element as any).mozRequestFullScreen();
        } else if ((element as any).webkitRequestFullscreen) { // Chrome, Safari and Opera
            (element as any).webkitRequestFullscreen();
        } else if ((element as any).msRequestFullscreen) { // IE/Edge
            (element as any).msRequestFullscreen();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription?.unsubscribe();
        });

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
