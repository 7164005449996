<div class="container mx-auto py-10 sm:py-14 px-6 text-gray-600">
    <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1 px-4 md:px-0">
            <h3 class="text-lg font-semibold uppercase text-chprimary">Profile Information</h3>
            <div class="flex items-center gap-2 mb-4">
                <label class="text-sm font-medium text-toddbluelighter mr-2">Profile Status:</label>
                <div class="{{profileVerifiedBgColor}} flex justify-center py-1 px-2 items-center rounded-md font-medium">{{verified.toUpperCase()}}</div>
                <div class="{{profileCompleteBgColor}} flex justify-center py-1 px-2 items-center rounded-md font-medium">{{completed.toUpperCase()}}</div>
            </div>
            <p class="my-2 italic" *ngIf="verified === 'Not Verified'">Our admin team will verify your account. Once verified you will be able to use this app.</p>
            <p class="my-2 italic" *ngIf="completed === 'Not Completed'">Please complete your profile before using the app.</p>
        </div>

        <div class="mt-5 md:col-span-2 md:mt-0 shadow rounded-md bg-white">

            <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">

                <div class="grid grid-cols-6 gap-6 bg-white rounded-md px-4 py-5 sm:p-6">

                    <div class="col-span-6 sm:col-span-3">
                        <label class="block mb-1 font-medium">Email</label>
                        {{displayEmail}}
                        <span class="block text-sm italic mt-1">Contact admin to change your email address</span>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label for="first-name" class="block mb-1 font-medium">First name</label>
                        <input type="text"
                               pInputText
                               required
                               formControlName="firstName"
                               name="first-name"
                               id="first-name"
                               autocomplete="given-name"
                               class="w-full"/>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label for="last-name" class="block mb-1 font-medium">Last name</label>
                        <input type="text"
                               pInputText
                               required
                               formControlName="lastName"
                               name="last-name"
                               id="last-name"
                               autocomplete="family-name"
                               class="w-full">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label for="last-name" class="block mb-1 font-medium">Mobile Number</label>
                        <input type="text"
                               pInputText
                               required
                               formControlName="mobile"
                               name="mobile"
                               id="mobile"
                               autocomplete="tel"
                               class="w-full">
                    </div>
                    <div class="col-span-6">
                        <label for="permissionRoles" class="block mb-1 font-medium">Permission Roles</label>
                        <ng-container *ngIf="isAdmin$ | async; else permissionRoleNotAdmin">
                            <p-multiSelect [options]="permissionOptions"
                                           formControlName="permissionRoles"
                                           defaultLabel="Select Permission Roles"
                                           optionLabel="name"
                                           optionValue="name"
                                           display="chip"
                                           styleClass="w-full"
                                           [showToggleAll]="false"
                                           [filter]="false"
                                           id="permissionRoles"></p-multiSelect>
                        </ng-container>
                        <ng-template #permissionRoleNotAdmin>
                            <span>{{currentUserPermissionRoles$ | async}}</span>
                            <span class="block text-sm italic mt-1">Only administrators can change your roles.</span>
                        </ng-template>
                    </div>
                    <div class="col-span-6">
                        <label class="block mb-1 font-medium">Show Chords?</label>
                        <p-selectButton [options]="yesNoPreferences"
                                        formControlName="showChords"
                                        optionLabel="option"
                                        required
                                        optionValue="option"></p-selectButton>
                    </div>
                    <div class="col-span-6">
                        <label class="block mb-1 font-medium">Show the Nashville Number System?</label>
                        <p-selectButton [options]="yesNoPreferences"
                                        formControlName="showNashville"
                                        optionLabel="option"
                                        required
                                        optionValue="option"></p-selectButton>
                    </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-b-md">
                    <button type="submit"
                            [disabled]="profileForm.invalid || isProcessing"
                            [ngClass]="{'bg-slate-400 hover:bg-slate-400': isProcessing || profileForm.invalid,
            'hover:bg-chprimary bg-chgforest': !isProcessing && !profileForm.invalid }"
                            id="hoops-profile-form-submit"
                            class="justify-center rounded-md border
            border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                        {{isProcessing ? 'Updating...' : profileForm.invalid ? 'Please fill in all fields' :'Update Profile'}}
                    </button>
                </div>

            </form>

        </div>
    </div>
</div>

