import {Component, OnInit, ViewChild} from '@angular/core';
import {Profile} from "../../../core/models";
import {Observable} from "rxjs";
import {Table} from "primeng/table";
import {Router} from "@angular/router";
import {CustomMessageService} from "../../../core/services/custom-message.service";
import {ChorubyFirestoreService} from "../../../core/services/choruby-firestore.service";

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    providers: [CustomMessageService],
})
export class UserManagementComponent implements OnInit {

    @ViewChild('profilesTable') profilesTable: Table;
    protected profiles$: Observable<Array<Profile>>;

    constructor(
        private firestore: ChorubyFirestoreService,
        private router: Router,
        private messageService: CustomMessageService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.profiles$ = this.firestore.getUserProfiles$();
    }

    applyFilterGlobal($event: any) {
        console.log($event.target.value);
        this.profilesTable.filterGlobal(($event.target as HTMLInputElement).value, 'contains');
        console.log(this.profilesTable);
    }

    clear(table: Table) {
        table.clear();
    }

    verifyProfile(email: string) {
        this.firestore.verifyProfile(email).then(() => {
            this.messageService.showSuccess('Verified', 'Profile has been verified');
        }).catch((error) => {
            this.messageService.showError('Error verifying', error.message);
        });
    }

    editProfile(email: any) {
        this.router.navigate(['/profile', email]);
    }

}
