import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'chordsLyricsParser'
})
export class ChordsLyricsParserPipe implements PipeTransform {

    private generateScaleDegrees(key: string): string[] {
        const allNotesSharp = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
        const allNotesFlat = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];
        let allNotes = allNotesSharp;

        if (['F', 'Bb', 'Eb', 'Ab', 'Db', 'Gb', 'Cb'].includes(key)) {
            allNotes = allNotesFlat;
        }

        const keyIndex = allNotes.indexOf(key);
        const scaleDegrees = [
            allNotes[keyIndex % 12], // 1st degree
            allNotes[(keyIndex + 2) % 12], // 2nd degree
            allNotes[(keyIndex + 4) % 12], // 3rd degree
            allNotes[(keyIndex + 5) % 12], // 4th degree
            allNotes[(keyIndex + 7) % 12], // 5th degree
            allNotes[(keyIndex + 9) % 12], // 6th degree
            allNotes[(keyIndex + 11) % 12], // 7th degree
        ];
        return scaleDegrees;
    }

    private alterChord(chord: string): string {
        const allNotesSharp = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
        const allNotesFlat = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];

        if (chord.endsWith('b#') || chord.endsWith('#b')) {
            return chord.substring(0, chord.length - 2);
        } else if (chord.endsWith('##')) {
            const noteIndex = allNotesSharp.indexOf(chord.substring(0, chord.length - 2));
            return allNotesSharp[(noteIndex + 2) % 12] + chord.slice(-2, -2);
        } else if (chord.endsWith('bb')) {
            const noteIndex = allNotesFlat.indexOf(chord.substring(0, chord.length - 2));
            return allNotesFlat[(noteIndex - 2 + 12) % 12] + chord.slice(-2, -2);
        } else {
            return chord;
        }
    }


    transform(value: string, keyInput?: string, showNumbers?: boolean): string {
        let key = keyInput || 'C';
        const chordRegex = /\[((\d{1,2})(.*?))(?:\/(\d+)(.*?))?\]/g; // group1: entire chord (including extra), group2: numeric degree, group3: the rest, group4: optional numeric degree after slash, group5: modifier after slash
        // const chordRegex = /\[(\d+)(.*?)(?:\/(\d+)(.*?))?\]/g; // group1: numeric degree, group2: the rest, group3: optional numeric degree after slash, group4: modifier after slash
        const commentRegex = /^(#.*$)/gm;
        const scaleDegrees = this.generateScaleDegrees(key);
        const spaceRegex = /\[(_+)\]/g;
        value = value.replace(/^(\[\d+.*?\])(.*)$/gm, ' $1$2');

        if (showNumbers) {
            const numberRegex = /\[(.*?)\]/g; // group1: anything inside the square brackets
            const withSpace = value.replace(spaceRegex, function (match) {
                // Remove square brackets and replace each underscore with a space
                return match.slice(1, -1).replace(/_/g, ' ') + ' ';
            });
            const withNumbersSpan = withSpace.replace(numberRegex, (match, content) => {
                return '<span class="absolute -mt-[1.1em] text-[0.85em] text-red-500 font-semibold">' + content + '</span>';
            });

            return withNumbersSpan.replace(commentRegex, '<span class="text-red-400 italic">$1</span>');
        } else {
            const withChordsSpan = value.replace(chordRegex, (match, entireChord, degree, extra, degreeAfterSlash, modifierAfterSlash) => {
                let degreeBase = degree.length === 2 ? degree[0] : degree;
                let degreeModifier = degree.length === 2 ? degree[1] : '';
                let chordBase = scaleDegrees[parseInt(degreeBase) - 1] || '';
                let extraBase = extra ? extra.replace(/[^#b]/g, '') : ''; // get base chord from extra
                let extraMod = degreeModifier + (extra ? extra.replace(/[#b]/g, '') : ''); // get modifier from extra
                let chord = this.alterChord(chordBase + extraBase);

                let chordAfterSlashBase = degreeAfterSlash ? scaleDegrees[parseInt(degreeAfterSlash) - 1] : '';
                let modifierAfterSlashBase = modifierAfterSlash ? modifierAfterSlash.replace(/[^#b]/g, '') : '';
                let modifierAfterSlashMod = modifierAfterSlash ? modifierAfterSlash.replace(/[#b]/g, '') : '';
                let chordAfterSlash = degreeAfterSlash ? this.alterChord(chordAfterSlashBase + modifierAfterSlashBase) : '';

                let slash = degreeAfterSlash ? '/' : '';
                return '<span class="absolute -mt-[1.1em] text-[0.85em] text-red-500 font-semibold">' + chord + extraMod + slash + chordAfterSlash + modifierAfterSlashMod + '</span>';
            });


            const withSpace = withChordsSpan.replace(spaceRegex, function (match) {
                // Remove square brackets and replace each underscore with a space
                return match.slice(1, -1).replace(/_/g, ' ') + ' ';
            });

            return withSpace.replace(commentRegex, '<span class="text-red-400 italic">$1</span>');
        }
    }

}