import {Component, OnDestroy, OnInit} from '@angular/core';
import {Song} from "../../core/models";
import {ChorubyFirestoreService} from "../../core/services/choruby-firestore.service";
import {ChordsLyricsParserPipe} from "../../core/services/chord-mgmt/chords-lyrics-parser.pipe";
import {RemoveChordsParserPipe} from "../../core/services/chord-mgmt/remove-chords-parser.pipe";
import {Subject, Subscription} from "rxjs";
import {Title} from "@angular/platform-browser";
import {SongControlsService} from "../../core/services/song-controls.service";
import {CurrentSongServiceService} from "../../core/services/current-song-service.service";
import {ProfileService} from "../../core/services/profile.service";

@Component({
    selector: 'app-play',
    templateUrl: './play.component.html',
})
export class PlayComponent implements OnInit, OnDestroy {

    protected currentSong: Song | null;
    protected currentVerseIndex: number;
    protected nextVerseSong: Song | null;
    protected nextVerseIndex: number;

    protected currentSongTransformedLyrics: Song['lyrics'];

    protected nextSongTransformedLyrics: Song['lyrics'];
    protected chordsLyricsParserPipe: ChordsLyricsParserPipe;
    protected removeChordsParserPipe: RemoveChordsParserPipe;
    protected showChords: boolean;
    protected showNashville: boolean;

    private subscriptions: Subscription[] = [];
    private unsubscribe$ = new Subject<void>();
    protected verseCountForCurrentSong: number[] = [];
    protected verseCountForNextSong: number[] = [];
    private songVerseOrder: number[];
    private nextSongVerseOrder: number[];

    protected nextVerseKey: string;
    protected currentVerseKey: string;
    protected manualKey: boolean = false;

    constructor(
        private currentSongService: CurrentSongServiceService,
        private titleService: Title,
        private songControls: SongControlsService,
        private profile: ProfileService,
    ) {
        this.chordsLyricsParserPipe = new ChordsLyricsParserPipe();
        this.removeChordsParserPipe = new RemoveChordsParserPipe();
    }

    async ngOnInit(): Promise<void> {
        this.titleService.setTitle('Choruby - Play');

        this.subscriptions.push(
            this.currentSongService.getCurrentSong().subscribe((song) => {
                    this.currentSong = song;
                    console.log('current song', song)
                    if (song && this.currentSong) {
                        this.songVerseOrder = this.songControls.getVerseOrderIndex(song);
                        this.currentSongTransformedLyrics = this.songControls.duplicateVersesInOrder(this.songVerseOrder, this.currentSong);
                        this.verseCountForCurrentSong = this.songControls.updateVerseNumberForSong(song);
                    }
                }
            ));

        this.subscriptions.push(
            this.currentSongService.getCurrentVerseIndex().subscribe((index) => {
                this.currentVerseIndex = index;
            })
        );

        this.subscriptions.push(
            this.currentSongService.getNextSong().subscribe((song) => {

                this.nextVerseSong = song;
                if (song && this.nextVerseSong) {
                    this.nextSongVerseOrder = this.songControls.getVerseOrderIndex(song);
                    this.nextSongTransformedLyrics = this.songControls.duplicateVersesInOrder(this.nextSongVerseOrder, this.nextVerseSong);
                    this.verseCountForNextSong = this.songControls.updateVerseNumberForSong(song);
                }

            })
        );

        this.subscriptions.push(
            this.currentSongService.getNextVerseIndex().subscribe((index) => {
                this.nextVerseIndex = index;
            })
        );

        this.subscriptions.push(
            this.currentSongService.getCurrentSongKey().subscribe((key) => {
                this.currentVerseKey = key;
                console.log('Current Key', key);
            })
        );
        this.subscriptions.push(
            this.currentSongService.getNextSongKey().subscribe((key) => {
                this.nextVerseKey = key;
                console.log('Next Key', key);
            })
        );

        this.subscriptions.push(
            this.profile.getShowChords().subscribe((showChords) => {
                this.showChords = showChords;
            }));

        this.subscriptions.push(
            this.profile.getShowNashville().subscribe((show) => {
                this.showNashville = show;
            }));

    }

    protected parseLyricsContent(content: string, key?: string): string {
        if (this.showChords) {
            return this.chordsLyricsParserPipe.transform(content, key, this.showNashville);
        } else {
            return this.removeChordsParserPipe.transform(content);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription?.unsubscribe();
        });

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
