export enum LyricTypes {
    verse = 'Verse',
    chorus = 'Chorus',
    altchorus = 'Alt Chorus',
    bridge = 'Bridge',
    prechorus = 'Pre-Chorus',
    intro = 'Intro',
    outro = 'Outro',
    refrain = 'Refrain',
    interlude = 'Interlude',
    other = 'Other',
}