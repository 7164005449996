import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProfileService} from "../../core/services/profile.service";
import {filter, Observable, Subscription} from "rxjs";
import {CustomMessageService} from "../../core/services/custom-message.service";
import {AuthService} from "../../core/services/auth.service";
import {SwUpdate} from "@angular/service-worker";
import {PermissionControlService} from "../../core/services/permission-control.service";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    providers: [ProfileService, CustomMessageService]
})

export class SidebarComponent implements OnInit, OnDestroy {
    protected collapseShow = "hidden";
    protected showChords = false;
    protected showNashville = false;
    private subscriptions: Subscription[] = [];
    protected isAdmin$: Observable<boolean>
    protected isServiceController$: Observable<boolean>

    constructor(
        private profileService: ProfileService,
        private auth: AuthService,
        private messageService: CustomMessageService,
        private swUpdate: SwUpdate,
        private userRoles: PermissionControlService,
    ) {
        this.subscriptions.push(
            this.swUpdate.versionUpdates.pipe(
                filter(event => event.type === 'VERSION_READY')
            ).subscribe((event) => {
                if ('currentVersion' in event && 'latestVersion' in event) {
                    if (confirm("New version available. Load New Version?")) {
                        window.location.reload();
                    }
                }
            })
        );
    }


    reloadPage() {
        window.location.reload();
    }

    toggleChords() {
        if (this.showChords) {
            this.profileService.setShowChords('No').catch(err =>
                this.messageService.showError('Error Setting Chords', err)
            );
        } else {
            this.profileService.setShowChords('Yes').catch(err =>
                this.messageService.showError('Error Setting Chords', err)
            );
        }
    }

    toggleNashville() {
        if (this.showNashville) {
            this.profileService.setShowNashville('No').catch(err =>
                this.messageService.showError('Error Setting Chords/Nashville Number System', err)
            );
        } else {
            this.profileService.setShowNashville('Yes').catch(err =>
                this.messageService.showError('Error Setting Chords/Nashville Number System', err)
            );
        }
    }

    toggleCollapseShow(classes: any) {
        this.collapseShow = classes;
    }

    ngOnInit(): void {

        this.isAdmin$ = this.userRoles.isAdmin();
        this.isServiceController$ = this.userRoles.isServiceController();

        this.subscriptions.push(this.profileService.getShowChords().subscribe(showChords => {
            this.showChords = showChords;
        }));
        this.subscriptions.push(this.profileService.getShowNashville().subscribe(showNashville => {
            this.showNashville = showNashville;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s?.unsubscribe());
    }

    logout() {
        this.auth.logout();
    }

    sendMessagePopup() {
        console.log('Sending message');
    }
}
