import {Injectable} from '@angular/core';
import {ChorubyFirestoreService} from './choruby-firestore.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Profile} from '../models';
import {map, take} from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    private profileSubject: BehaviorSubject<Profile | null>;

    constructor(
        private chorubyFirestoreService: ChorubyFirestoreService,
    ) {
        this.profileSubject = new BehaviorSubject<Profile | null>(null);
        this.loadUserProfile();
    }

    getProfile(): Observable<Profile | null> {
        return this.profileSubject.asObservable();
    }

    getShowChords(): Observable<boolean> {
        return this.profileSubject.pipe(
            map((profile) => (profile && profile.showChords === 'Yes') || false)
        );
    }

    getShowNashville(): Observable<boolean> {
        return this.profileSubject.pipe(
            map((profile) => (profile && profile.showNashville === 'Yes') || false)
        );
    }

    async setShowChords(value: 'Yes' | 'No'): Promise<void> {
        this.profileSubject.pipe(take(1)).subscribe((profile) => {
            if (profile) {
                profile.showChords = value ? 'Yes' : 'No';
                return this.chorubyFirestoreService.setShowChordsPreference(value);
            }
            return Promise.reject('No profile found');
        });
    }

    async setShowNashville(value: 'Yes' | 'No'): Promise<void> {
        console.log('this is being called');
        this.profileSubject.pipe(take(1)).subscribe((profile) => {
            if (profile) {
                profile.showNashville = value ? 'Yes' : 'No';
                return this.chorubyFirestoreService.setShowNashvillePreference(value);
            }
            return Promise.reject('No profile found');
        });
    }

    getSelectedSongListId() {
        return this.profileSubject.getValue()?.selectedSongList;
    }

    private loadUserProfile(): void {
        this.chorubyFirestoreService.getUserProfile$().subscribe((profile) => {
            this.profileSubject.next(profile);
        });
    }
}