<div *ngIf="songList" class="container mx-auto pt-5 pb-14 px-6 text-gray-800">

    <div class="flex justify-center md:justify-end items-center gap-4">
        <button class="mb-5 rounded-md border bg-chprimary hover:bg-chcaribbean
            border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
                routerLink="/song-list/{{songList.uid}}/edit">
            Edit
        </button>
        <button (click)="setGlobalList()"
                [ngClass]="currentService?.list === songList.uid ? 'bg-chgforest' : 'bg-gray-400'"
                class="mb-5 rounded-md border
            border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
        >
            {{currentService?.list === songList.uid ? 'Presenting...' : 'Present List'}}
        </button>
        <button (click)="activateSongList()"
                [ngClass]="userProfile?.selectedSongList === songList.uid ? 'bg-chgforest' : 'bg-gray-400'"
                class="mb-5 rounded-md border
            border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
            {{userProfile?.selectedSongList === songList.uid ? 'Selected List' : 'Activate for Me'}}
        </button>
        <button (click)="deleteList()"
                class="mb-5 rounded-md border bg-amber-500 hover:bg-amber-500
            border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
            Archive
        </button>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-5 gap-6 grid-flow-dense">

        <div class="col-span-full p-4 shadow rounded-md bg-white">
            <h3 class="text-base font-medium uppercase text-gray-500">Basic Song List Information</h3>
            <div class="border-t border-gray-200 mb-2 mt-1"></div>
            <div class="flex items-center mb-4">
                <label class="text-sm font-medium text-chcaribbean mr-2">Title:</label>
                <p class="font-medium">{{songList.title}}</p>
            </div>
            <div class="flex items-center mb-4">
                <label class="text-sm font-medium text-chcaribbean mr-2">List Owner:</label>
                <p class="font-medium">{{songList.listOwnerName}}</p>
            </div>
            <div class="mb-4">
                <p class="text-chprimary"><label class="text-sm font-medium text-chcaribbean mr-1">Notes:</label>
                    {{songList.notes}}</p>
            </div>
        </div>

    </div>
    <div class="border-t border-gray-200 my-7"></div>
    <div>
        <p-orderList (onReorder)="onListReorder($event)" [dragdrop]="true" controlsPosition="right"
                     [listStyle]="{'background': '#ebefe8' }" [value]="songList.songs"
                     header="Song List">
            <ng-template let-song let-i="index"  pTemplate="item">
                <div class="grid grid-cols-6 gap-2 p-4 bg-white shadow rounded border border-gray-300">

                    <div class="flex items-center col-span-4 md:col-span-1"><span
                            class="font-semibold"># </span>{{ song?.uid }}</div>

                    <div class="flex items-center justify-end md:justify-start col-span-2 md:col-span-1 gap-1"><span
                            class="font-semibold">Key:</span>{{ song?.key }}</div>

                    <div class="flex items-center col-span-4 md:col-span-3 capitalize text-chprimary font-medium" routerLink="/song/{{song?.uid}}/view">{{ song?.title }}</div>

                    <div class="flex items-center justify-end col-span-2 md:col-span-1">
                        <button (click)="removeSongByIndex(songList.uid, i)"
                                class="items-center
                  rounded-md border border-transparent py-2 px-2 text-xs text-white
                  focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2 bg-red-400 hover:bg-red-500"
                                type="button">
                            Remove
                        </button>
                    </div>
                </div>
            </ng-template>
        </p-orderList>
        <div class="flex items-center my-4">
            <div class="grow">
                <input #customItem class="w-full"
                       pInputText
                       required
                       placeholder="Add custom items, song items, testimonies here..."
                       type="text"/>
            </div>
            <div>
                <button class="ml-5 rounded-md border bg-chprimary hover:bg-chcaribbean border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
                        [disabled]="!customItem.value"
                        (click)="addCustomItem(customItem)">
                    Add Custom Item
                </button>
            </div>
        </div>
    </div>
</div>


<div *ngIf="!songList" class="w-full h-[70vh] flex justify-center items-center bg-gray-100">
    <div class="text-center text-gray-600 font-medium text-2xl overflow-y-scroll">Loading Song List...</div>
</div>