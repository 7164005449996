import { Injectable } from '@angular/core';
import {Observable, shareReplay} from "rxjs";
import {ChorubyFirestoreService} from "./choruby-firestore.service";

@Injectable({
  providedIn: 'root'
})
export class SongsCacheService {
  private songsCache: Observable<any[]>;

  constructor(private firestore: ChorubyFirestoreService) { }

  getSongs(): Observable<any[]> {
    if (!this.songsCache) {
      this.songsCache = this.firestore.getSongs$().pipe(
          shareReplay(1) // cache the latest emitted value
      );
    }
    return this.songsCache;
  }
}
