import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomMessageService} from "../../../core/services/custom-message.service";
import {ChorubyFirestoreService} from "../../../core/services/choruby-firestore.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Keys, LyricTypes, Song, Tempo} from "../../../core/models";
import {firstValueFrom} from "rxjs";
import {ChorubyFunctionsService} from "../../../core/services/choruby-functions.service";

@Component({
    selector: 'app-song-edit',
    templateUrl: './song-edit.component.html',
    providers: [CustomMessageService]
})
export class SongEditComponent implements OnInit, OnDestroy {

    protected isProcessing = false;
    protected song: Song;
    protected newSong: boolean = false;
    protected songForm: FormGroup = this.fb.group({
        title: ['', [Validators.required]],
        key: [''],
        tempo: [0],
        lyrics: this.fb.array([], this.requireAtLeastOneSelection()),
        author: [''],
        copyright: [''],
    });
    protected keyOptions = Object.values(Keys).map(key => ({name: key}));
    protected tempoOptions = Object.values(Tempo).map(tempo => ({value: tempo}));
    protected lyricTypes = Object.values(LyricTypes).map(key => ({name: key}));


    constructor(
        private messageService: CustomMessageService,
        private firestore: ChorubyFirestoreService,
        private functions: ChorubyFunctionsService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private fb: FormBuilder,
    ) {
    }

    protected get lyrics(): FormArray {
        return this.songForm.get('lyrics') as FormArray;
    }

    async ngOnInit() {
        this.titleService.setTitle('Edit Song');
        const songId = this.route.snapshot.paramMap.get('songId');
        if (songId) {
            await this.getSong(songId ?? '');
        } else {
            this.newSong = true;
        }
    }

    //todo: make so it doesn't change while editing
    async getSong(songNumber: string) {

        this.song = await firstValueFrom(this.firestore.getSong$(songNumber));
        if (this.song) {
            this.patchSongToForm();
        } else {
            this.messageService.showError('Error loading song', 'Song not found or a system error occurred');
        }

    }

    patchSongToForm(): void {
        this.songForm.patchValue({
            title: this.song.title,
            key: this.song.key,
            tempo: this.song.tempo ?? 0,
            author: this.song.author,
            copyright: this.song.copyright,
        });

        // Clear the existing lyrics form array
        this.lyrics.clear();

        // Loop through the song lyrics and create a form group for each item
        this.song.lyrics.forEach(lyric => {
            const lyricItem = this.fb.group({
                type: [lyric.type, Validators.required],
                content: [lyric.content, Validators.required],
            });
            this.lyrics.push(lyricItem);
        });
    }

    addLyricItem() {
        const lyricItem = this.fb.group({
            type: ['', Validators.required],
            content: ['', Validators.required],
        });
        this.lyrics.push(lyricItem);
    }


    removeLyricItem(i: any) {
        this.lyrics.removeAt(i);
    }

    requireAtLeastOneSelection(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const items = control.value as [];
            if (items && items.length > 0) {
                return null;
            } else {
                return {requireAtLeastOneSelection: true};
            }
        };
    }

    async onSubmit() {
        this.isProcessing = true;
        const formValue = this.songForm.getRawValue();
        let uid = this.song?.uid ?? '';

        if (this.newSong) {
            uid = await this.assureSongId();
        }
        if (uid) {
            await this.firestore.updateSong(formValue, uid).then((res) => {
                this.messageService.showSuccess('Updated', 'Song updated successfully');
                this.router.navigate(['song', uid, 'view']);
                this.isProcessing = false;
            }).catch(error => {
                this.messageService.showError('Error', 'There was an error updating the song, check your console log for details');
                console.log('error updating song:', error);
            });
        }
    }

    ngOnDestroy(): void {
    }

    private async assureSongId() {
        try {
            return await this.functions.createNewSong().then(r => r.data as string);
        } catch (e) {
            this.messageService.showError('Error', 'There was an error creating the song, check your console log for function failure details');
            console.log('error creating song:', e);
            return '';
        }
    }

}

