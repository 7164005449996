<div #scrollContainer class="container mx-auto pt-4 pb-14 px-6 text-gray-800">
        <div class="z-20 sticky top-0 bg-gray-100 py-2 rounded-md px-2 flex justify-end items-center gap-4 flex-wrap">
            <label class="font-semibold text-chprimary mr-2">Jump to: </label>
            <a routerLink="."
                    fragment="0001"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                            border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                1
            </a>
            <a routerLink="."
                    fragment="0050"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                            border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                50
            </a>
            <a [routerLink]="['/songs/book']"
                    fragment="0100"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                            border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                100
            </a>
            <a [routerLink]="['/songs/book']"
                    fragment="0150"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                            border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                150
            </a>
            <a [routerLink]="['/songs/book']"
                    fragment="0250"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                            border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                250
            </a>
            <a [routerLink]="['/songs/book']"
                    fragment="0300"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                            border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                300
            </a>
            <a [routerLink]="['/songs/book']"
                    fragment="0350"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                            border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                350
            </a>
            <a [routerLink]="['/songs/book']"
                    fragment="0400"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                            border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                400
            </a>
            <a [routerLink]="['/songs/book']"
                    fragment="0450"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                            border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                450
            </a>
            <a [routerLink]="['/songs/book']"
                    fragment="0500"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                            border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                500
            </a>
        </div>
    <div class="border-t border-gray-200 my-7"></div>
    <div *ngFor="let song of songs$ | async">

        <div class="flex gap-4 justify-between my-4">
            <div class="flex flex-col">
                <h2 [routerLink]="['/song', song?.uid, 'view']" class="uppercase cursor-pointer text-chprimary text-lg"
                    id="{{song.uid}}">{{song.uid}} - {{song.title}}</h2>
                <p>Key: <span class="text-red-500 font-semibold">{{song?.key}}</span></p>

            </div>
            <div class="flex items-center">
                <div class="flex gap-4 justify-center">
                    <button (click)="playSongNow(song?.uid, song.key)" class="flex justify-center items-center rounded-md border bg-chgforest hover:bg-chcaribbean
                        border-transparent py-4 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
                    >
                        <i class="pi pi-play"></i>
                    </button>
                    <button (click)="addSongToPresentedList(song?.uid)"
                            class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                        border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                        <svg class="w-6 h-6"
                             fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <button (click)="addSongToCurrentSelectedList(song?.uid)"
                            class="flex gap-1 justify-center items-center rounded-md border bg-chcoral hover:bg-chcaribbean
                        border-transparent py-4 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                        <i class="pi pi-user"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-6 gap-6 grid-flow-dense">
            <div *ngFor="let lyric of song.lyrics; let i=index" class="lg:col-span-2 md:col-span-3 col-span-6 p-4 shadow rounded-md bg-white">

                <h3 class="text-base font-medium uppercase text-gray-500"
                >
                    {{lyric.type}}
                </h3>
                <div class="border-t border-gray-200 mb-5 mt-1"></div>
                <p [innerHTML]="parseLyricsContent(lyric.content, song.key)" [ngClass]="{'leading-chords': showChords}"
                   [ngStyle]="{'white-space': 'pre-wrap'}"
                   class="leading-loose"></p>
            </div>

        </div>
        <div class="border-t border-gray-200 my-7"></div>
    </div>
</div>