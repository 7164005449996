<!--<div class="text-center text-2xl text-red-500">! Work in progress !</div>-->
<section class="mx-auto p-5 pb-14">
    <div class="mb-4 flex justify-end">
        <button routerLink="/songs/book"
                class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                        border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 md:mr-1">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
            </svg>

            <span class="hidden md:inline">Songbook View (Preview)</span>
        </button>
    </div>

    <p-table #songsTable
             [globalFilterFields]="['uid', 'title', 'songContent']"
             [paginator]="true"
             [rowsPerPageOptions]="[100,200,1000]"
             [rows]="200"
             [showCurrentPageReport]="true"
             [value]="(songs$ | async) ?? []"
             styleClass="p-datatable-striped text-sm shadow-md p-datatable-gridlines p-datatable-sm">

        <ng-template pTemplate="caption">
            <div class="flex">

                <button (click)="clear(songsTable)"
                        class="p-button-outlined"
                        icon="pi pi-filter-slash"
                        label="Clear"
                        pButton></button>

                <span class="p-input-icon-left ml-auto w-96">
                    <i class="pi pi-search"></i>
                    <input (input)="applyFilterGlobal($event)"
                           class="w-full"
                           pInputText
                           placeholder="Search keyword"
                           type="text"/>
        </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>

                <!--SONG NUMBER-->
                <th class="md:w-1/12 w-1/6">
                    <p-columnFilter [showClearButton]="false"
                                    [showMenu]="false"
                                    field="uid"
                                    matchMode="contains"
                                    type="text"></p-columnFilter>
                </th>

                <!--TITLE-->
                <th class="md:w-3/12 w-2/6">
                    <p-columnFilter [showClearButton]="false"
                                    [showMenu]="false"
                                    field="title"
                                    matchMode="contains"
                                    type="text"></p-columnFilter>
                </th>

                <!--first verse-->
                <th class="lg:w-4/12 lg:table-cell hidden">
                    <p-columnFilter [showClearButton]="false"
                                    [showMenu]="false"
                                    field="firstVerse"
                                    matchMode="contains"
                                    type="text"></p-columnFilter>
                </th>

                <!--KEY-->
                <th class="md:w-1/12 w-1/6">
                    <p-columnFilter [showClearButton]="false"
                                    [showMenu]="false"
                                    field="key"
                                    matchMode="contains"
                                    type="text"></p-columnFilter>
                </th>

                <!--TEMPO-->
                <th class="md:w-1/12 w-1/6">
                    <p-columnFilter [showClearButton]="false"
                                    [showMenu]="false"
                                    field="tempo"
                                    matchMode="contains"
                                    type="text"></p-columnFilter>
                </th>

                <!--ACTIONS-->
                <th class="md:w-2/12 w-1/6">
                </th>

            </tr>
            <tr>
                <ng-container *ngFor="let header of tableHeaders">
                    <ng-container *ngIf="header.field; else noField">
                        <th [class]="header.hiddenOnMobile ? 'lg:table-cell hidden' : ''"
                            [pSortableColumn]="header.field">
                            {{ header.label }}
                            <div class="hidden lg:inline">
                                <p-sortIcon [field]="header.field"></p-sortIcon>
                            </div>
                        </th>
                    </ng-container>
                    <ng-template #noField>
                        <th [class]="header.hiddenOnMobile ? 'lg:table-cell hidden' : ''">{{ header.label }}</th>
                    </ng-template>
                </ng-container>
            </tr>
        </ng-template>

        <ng-template let-song pTemplate="body">
            <tr class="cursor-pointer">
                <td routerLink="/song/{{song?.uid}}/view">
                    <div class="text-center">{{song?.uid }}</div>
                </td>
                <td routerLink="/song/{{song?.uid}}/view">
                    <div class="font-medium cursor-pointer lg:text-base">{{song?.title }}</div>
                </td>
                <td class="lg:table-cell hidden">{{song?.firstVerse }}</td>
                <td routerLink="/song/{{song?.uid}}/view">
                    <div class="text-center">{{song?.key }}</div>
                </td>
                <td routerLink="/song/{{song?.uid}}/view">
                    <div class="text-center">{{song?.tempo }}</div>
                </td>
                <td>
                    <div class="flex gap-4 justify-center">
                        <button (click)="playSongNow(song?.uid, song.key)" class="flex justify-center items-center rounded-md border bg-chgforest hover:bg-chcaribbean
                        border-transparent py-4 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
                        >
                            <i class="pi pi-play"></i>
                        </button>
                        <button (click)="addSongToPresentedList(song?.uid)"
                                class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                        border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                            <svg class="w-6 h-6"
                                 fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <button (click)="addSongToCurrentSelectedList(song?.uid)"
                                class="flex gap-1 justify-center items-center rounded-md border bg-chcoral hover:bg-chcaribbean
                        border-transparent py-4 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">
                            <i class="pi pi-user"></i>
                        </button>
                    </div>
                </td>
<!--                <td>-->
<!--                    <div class="flex gap-4 justify-center">-->
<!--                        <button (click)="setTempo(song?.uid, 'Slow')" class="flex justify-center items-center rounded-md border bg-chgforest hover:bg-chcaribbean-->
<!--                        border-transparent py-4 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"-->
<!--                        >S-->
<!--                        </button>-->
<!--                        <button (click)="setTempo(song?.uid, 'Medium')"-->
<!--                                class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean-->
<!--                        border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">-->
<!--                            M-->
<!--                        </button>-->
<!--                        <button (click)="setTempo(song?.uid, 'Fast')"-->
<!--                                class="flex gap-1 justify-center items-center rounded-md border bg-chcoral hover:bg-chcaribbean-->
<!--                        border-transparent py-4 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2">-->
<!--                            F-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </td>-->
            </tr>
        </ng-template>
    </p-table>

    <div class="flex justify-end items-center">
        <button class="my-5 rounded-md border bg-chprimary hover:bg-chcaribbean
            border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
                routerLink="/song/new">
            Add New Song
        </button>

    </div>
</section>
