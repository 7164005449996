<div *ngIf="currentList"
     class="lg:h-screen h-[calc(100vh-60px)] select-none grid grid-cols-9 xl:grid-cols-7 grid-rows-6 gap-4 p-2 xl:p-5 text-gray-800 grid-flow-dense">

    <!--    SONG LIST -->
    <div class="bg-white col-span-3 lg:col-span-2 row-span-2 lg:row-span-4 xl:row-span-5 rounded h-full">
        <p-listbox (onChange)="listSongSelectionChange($event.value)" [filter]="false"
                   [options]="currentList.songs"
                   [style]="{ width: '100%', height: '100%' }"
                   emptyFilterMessage="No song found"
                   filterPlaceHolder="Search Song title"
                   listStyleClass="h-full"
                   optionLabel="title"

        >
            <ng-template let-song pTemplate="item">
                <div class="grid grid-cols-2 xl:grid-cols-7 w-full mb-1 text-sm xl:text-base">
                    <div class="flex xl:justify-end items-center">{{ song?.uid }}</div>
                    <div class="flex xl:justify-center justify-end xl:items-center items-end">(<span
                            class="font-medium text-red-500">{{ song?.key }}</span>)
                    </div>
                    <div class="flex items-center col-span-2 xl:col-span-5 text-chprimary font-medium">{{ song?.title }}</div>
                </div>
            </ng-template>
        </p-listbox>
    </div>

    <!--    PRESENTED VERSE-->
    <div class="bg-white p-2 xl:col-span-3 lg:col-span-4 col-span-6 lg:row-span-3 row-span-2 grid grid-cols-1 grid-rows-1 rounded">
        <ng-container *ngIf="currentSong as song; else noCurrentSong">
            <div class="col-start-1 row-start-1 flex justify-between gap-2">
                <h2 class="uppercase text-sm text-gray-500 font-medium">Presented Verse</h2>
                <h2 class="text-base text-chprimary font-medium">{{currentSong?.title}}</h2>
            </div>

            <div class="col-start-1 row-start-1 flex justify-between items-end gap-2">
                <div class="text-gray-500">
                    {{currentSongTransformedLyrics[currentVerseIndex]?.type !== 'Chorus' && currentSongTransformedLyrics[currentVerseIndex]?.type
                    ? currentSongTransformedLyrics[currentVerseIndex]?.type + ' - ' + (currentVerseIndex + 1 - (verseCountForCurrentSong[currentVerseIndex] ?? 0)) : currentSongTransformedLyrics[currentVerseIndex]?.type ?? ''}}
                </div>
                <div class="text-red-500 font-medium">
                    {{currentVerseKey}}
                </div>
            </div>

            <div appDynamicFontSize="150"
                 class="col-start-1 row-start-1 justify-center items-center text-center text-lg flex">
                <div class="relative" id="lyricContentDiv">
                    <p #lyricsContent
                       [innerHTML]="parseLyricsContent((currentSongTransformedLyrics[currentVerseIndex])?.content ?? '(blank)', currentVerseKey)"
                       [ngClass]="{'leading-chords': showChords}"
                       [ngStyle]="{'white-space': 'pre-wrap'}" class="leading-normal h-full overflow-auto pb-4 px-2 pt-8"></p>
                </div>
            </div>

        </ng-container>
        <ng-template #noCurrentSong>
            <h2 class="uppercase text-sm text-gray-500 font-medium">No Current Song</h2>
        </ng-template>
    </div>

    <!--  PRESENTATION  CONTROLS-->
    <div class="bg-white p-2 xl:col-span-2 col-span-2 row-span-1 col-start-1 lg:col-end-3 col-end-5 rounded flex flex-col gap-1">
        <h2 class="uppercase text-sm text-gray-500 font-medium">Presentation Controls</h2>
        <div class="grow flex justify-center items-center gap-4">
            <button (click)="blank(true)"
                    [ngClass]="blankPresentation ? 'bg-red-500' : 'bg-red-200'"
                    class="flex justify-center items-center rounded-md border hover:bg-red-600
                        border-transparent py-3 px-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
            >
                <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg><span *ngIf="!blankPresentation" class="ml-1 hidden md:inline">Blank</span>
            </button>
            <button (click)="blank(false)"
                    [ngClass]="blankPresentation ? 'bg-gray-300' : 'bg-chgforest'"
                    class="flex justify-center items-center rounded-md border hover:bg-chgforest
                        border-transparent py-3 px-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                </svg><span *ngIf="blankPresentation" class="ml-1 hidden md:inline">Play</span>
            </button>
        </div>
    </div>

    <!--    UPCOMING VERSE-->
    <div (click)="switchNextVerseToCurrent()"
         [ngClass]="!nextVerseClickable ? 'bg-gray-300' : 'bg-white'"
         class="p-2 xl:col-span-2 lg:col-span-3 col-span-5 xl:row-span-2 lg:row-span-3 row-span-2 grid grid-cols-1 grid-rows-1 rounded">
        <ng-container *ngIf="nextVerseSong as song; else noNextVerseSong">

            <div class="col-start-1 row-start-1 flex justify-between gap-2">
                <h2 class="uppercase text-sm text-gray-500 font-medium">Next Verse</h2>

                <h2 class="text-sm text-chprimary font-medium">{{song?.title}}</h2>

            </div>

            <div class="col-start-1 row-start-1 flex justify-between items-end gap-2">
                <div class="text-gray-500">
                    {{nextSongTransformedLyrics[nextVerseIndex]?.type !== 'Chorus' && nextSongTransformedLyrics[nextVerseIndex]?.type
                    ? nextSongTransformedLyrics[nextVerseIndex]?.type + ' - ' + (nextVerseIndex + 1 - (verseCountForNextSong[nextVerseIndex] ?? 0)) : nextSongTransformedLyrics[nextVerseIndex]?.type ?? ''}}
                </div>
                <div class="text-red-500 font-medium"
                     [ngClass]="nextVerseKey !== currentVerseKey ? 'text-2xl font-semibold animate-bounce' : 'text-base'">
                    {{nextVerseKey}}
                </div>
            </div>

            <div appDynamicFontSize="110"
                 class="col-start-1 row-start-1 flex justify-center items-center text-center text-md">
                <div class="relative">
                    <p [innerHTML]="parseLyricsContent((nextSongTransformedLyrics[nextVerseIndex])?.content ?? '(blank)', nextVerseKey)"
                       [ngClass]="{'leading-chords': showChords}"
                       [ngStyle]="{'white-space': 'pre-wrap'}" class="leading-normal h-full overflow-auto pb-4 pt-8"></p>
                </div>
            </div>

        </ng-container>
        <ng-template #noNextVerseSong>
            <h2 class="uppercase text-sm text-chprimary font-medium">No Current Song</h2>
        </ng-template>

    </div>

    <!-- SONG CONTROLS-->
    <div class="bg-white p-2 col-start-1 lg:col-end-3 col-end-5 row-span-1 rounded flex flex-col gap-1">
        <h2 class="uppercase text-sm text-gray-500 font-medium">Song Controls</h2>
        <div class="grow flex justify-center items-center gap-2">
            <button (click)="keyChange(this.nextVerseKey, false)"
                    class="flex justify-center items-center rounded-md border hover:bg-chcaribbean bg-chprimary
                        border-transparent py-3 px-2 w-full text-xs text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
            >
                Key <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="ml-1 w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>


            </button>
            <button (click)="keyChange(this.nextVerseKey, true)"
                    class="flex justify-center items-center rounded-md border hover:bg-chcaribbean bg-chprimary
                        border-transparent py-3 px-2 w-full text-xs text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
            >Key <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="ml-1 w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>

            </button>

            <button (click)="noVerseManualSelection()"
                    class="flex justify-center items-center rounded-md border hover:bg-chcaribbean bg-chprimary
                        border-transparent py-3 px-2 w-full text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-toddblue focus:ring-offset-2"
            >End
            </button>
        </div>
    </div>

    <!--    SELECTED SONG VERSES-->
    <div class="p-2 xl:col-span-5 lg:col-span-7 col-span-9 row-span-3 overflow-y-scroll">
        <ng-container *ngIf="selectedSong as song; else noSelectedSong">
            <h2 class="uppercase text-base mb-2 text-chprimary font-medium">{{song?.title ?? 'No Selected Song'}}
                -
                verses</h2>
            <div class="grid grid-cols-2 xl:grid-cols-3 gap-3">
                <div (click)="nextVerseManualSelection(song.uid, i, song.key ?? '')"
                     *ngFor="let lyric of song.lyrics; let i=index"
                     [ngClass]="((song.uid === currentSong?.uid) && (currentVerseIndex === i)) ? 'bg-lime-50' :
                      ((song.uid === nextVerseSong?.uid) && (nextVerseIndex === i)) ? 'bg-fuchsia-50' : '' "
                     class="bg-white p-4 shadow rounded-md border cursor-pointer hover:bg-lime-50">
                    <h3 class="text-sm font-medium uppercase text-gray-500">
                        {{lyric.type !== 'Chorus' ? lyric.type + ' - ' + (i + 1 - (verseCountForSelectedSong[i] ?? 0)) : lyric.type}}
                    </h3>
                    <div class="border-t border-gray-300 mb-5 mt-1"></div>
                    <div class="relative">
                        <p [innerHTML]="parseLyricsContent(lyric.content, song?.key)" [ngClass]="{'leading-chords': showChords}"
                           [ngStyle]="{'white-space': 'pre-wrap'}" class="leading-loose"></p>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noSelectedSong>
            <h2 class="uppercase text-sm text-gray-500 font-medium">No Selected Song</h2>
        </ng-template>
    </div>

</div>


<div *ngIf="!currentList" class="w-full h-[70vh] flex justify-center items-center bg-gray-100">
    <div class="text-center text-gray-600 font-medium text-2xl overflow-y-scroll">Loading active song list...</div>
</div>
