import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeChordsParser'
})
export class RemoveChordsParserPipe implements PipeTransform {
  transform(value: string): string {
    const chordRegex = /\[(.*?)\]/g;
    const commentRegex = /^#.*\n?/gm;
    const withoutChords = value.replace(chordRegex, '');
    return withoutChords.replace(commentRegex, '');
  }
}