<section class="mx-auto p-5 pb-14">
  <a class="flex items-center text-chcaribbean mb-2 text-sm"
     href="./admin"><i class="pi pi-arrow-left mr-2"></i><span>Back to Admin</span></a>
  <h1 class="text-lg font-semibold uppercase text-chprimary mb-5">Admin User Management</h1>

  <p-table #profilesTable
           [globalFilterFields]="['firstName','lastName','email','profileCompleted','mobile', 'permissionRoles', 'verified']"
           [paginator]="true"
           [rowsPerPageOptions]="[25,50,100]"
           [rows]="25"
           [showCurrentPageReport]="true"
           [tableStyle]="{'min-width': '50rem'}"
           [value]="(profiles$ | async) ?? []"
           styleClass="p-datatable-striped text-sm shadow-md p-datatable-gridlines p-datatable-sm">

    <ng-template pTemplate="caption">
      <div class="flex">
        <button (click)="clear(profilesTable)"
                class="p-button-outlined"
                icon="pi pi-filter-slash"
                label="Clear"
                pButton></button>
        <span class="p-input-icon-left ml-auto w-96">
                    <i class="pi pi-search"></i>
                    <input (input)="applyFilterGlobal($event)"
                           class="w-full"
                           pInputText
                           placeholder="Search keyword"
                           type="text"/>
                </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <!--        #1-->
        <th style="min-width:10%; ">
          <p-columnFilter [showClearButton]="false" [showMenu]="false" field="firstName" matchMode="contains" type="text"></p-columnFilter>
        </th>
        <!--        #2-->
        <th style="min-width:10%; ">
          <p-columnFilter [showClearButton]="false" [showMenu]="false" field="lastName" matchMode="contains" type="text"></p-columnFilter>
        </th>
        <!--        #3-->
        <th style="min-width:12%; ">
          <p-columnFilter [showClearButton]="false" [showMenu]="false" field="email" matchMode="contains" type="text"></p-columnFilter>
        </th>
        <!--        #4-->
        <th style="min-width:10%; ">
          <p-columnFilter [showClearButton]="false" [showMenu]="false" field="mobile" matchMode="contains" type="text"></p-columnFilter>
        </th>
        <!--        #5-->

        <!--        #6-->
        <th style="min-width:12%; ">
          <p-columnFilter [showClearButton]="false" [showMenu]="false"
                          field="permissionRoles"
                          matchMode="contains"
                          type="text"></p-columnFilter>
        </th>
        <!--        #7-->
        <th style="min-width:10%; ">
          <p-columnFilter field="profileCompleted" type="boolean"></p-columnFilter>
        </th>
        <!--        #8-->
        <th style="min-width:10%; ">
          <p-columnFilter field="verified" type="boolean"></p-columnFilter>
        </th>
        <!--        #9-->
        <th></th>
      </tr>

      <tr>
        <!--        #1-->
        <th pSortableColumn="firstName">First Name
          <p-sortIcon field="firstName"></p-sortIcon>
        </th>
        <!--        #2-->
        <th pSortableColumn="lastName">Last Name
          <p-sortIcon field="lastName"></p-sortIcon>
        </th>
        <!--        #3-->
        <th pSortableColumn="email">Email
          <p-sortIcon field="email"></p-sortIcon>
        </th>
        <!--        #4-->
        <th pSortableColumn="mobile">Mobile
          <p-sortIcon field="mobile"></p-sortIcon>
        </th>

        <!--        #6-->
        <th pSortableColumn="permissionRoles">Role
          <p-sortIcon field="permissionRoles"></p-sortIcon>
        </th>
        <!--        #7-->
        <th class="items-center" pSortableColumn="profileCompleted">Profile Completed
        </th>
        <!--        #8-->
        <th class="items-center" pSortableColumn="verified">Verified
        </th>
        <!--        #9-->
        <th>Actions
        </th>
      </tr>
    </ng-template>

    <ng-template let-profile pTemplate="body">
      <tr>
        <!--        #1-->
        <td>{{profile.firstName}}</td>
        <!--        #2-->
        <td>{{profile.lastName}}</td>
        <!--        #3-->
        <td>{{profile.email}}</td>
        <!--        #4-->
        <td>{{profile.mobile}}</td>

        <!--        #6-->
        <td>
          <span *ngFor="let role of profile.permissionRoles; let isLast=last">
            {{role}}{{isLast ? '' : ', '}}
          </span>
        </td>
        <!--        #7-->
        <td><i [ngClass]="{'text-green-500 pi-check-circle': profile.profileCompleted, 'text-red-500 pi-times-circle': !profile.profileCompleted}"
               class="pi"></i>
        </td>
        <!--        #8-->
        <td><i [ngClass]="{'text-green-500 pi-check-circle': profile?.verified, 'text-red-500 pi-times-circle': !profile?.verified}"
               class="pi"></i>
        </td>
        <!--        #9-->
        <td>
          <div class="flex gap-2">
            <button (click)="editProfile(profile.email)" class="p-button-sm" icon="pi pi-pencil" pButton></button>
            <button (click)="verifyProfile(profile.email)" class="p-button-sm" icon="pi pi-check" pButton></button>
          </div>
      </tr>
    </ng-template>
  </p-table>
</section>
