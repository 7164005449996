import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CustomMessageService} from "../../core/services/custom-message.service";
import {ChorubyFirestoreService} from "../../core/services/choruby-firestore.service";
import {CurrentSongServiceService} from "../../core/services/current-song-service.service";
import {Song, SongList} from "../../core/models";
import {Subject, Subscription, switchMap, takeUntil} from "rxjs";
import {ProfileService} from "../../core/services/profile.service";
import {Title} from "@angular/platform-browser";
import {ChordsLyricsParserPipe} from "../../core/services/chord-mgmt/chords-lyrics-parser.pipe";
import {RemoveChordsParserPipe} from "../../core/services/chord-mgmt/remove-chords-parser.pipe";
import {SongControlsService} from "../../core/services/song-controls.service";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    providers: [CustomMessageService],
})
export class DashboardComponent implements OnInit, OnDestroy {
    @ViewChild('lyricsContent') lyricsContent: ElementRef;

    //Current Service Related
    protected currentList: SongList | null;
    protected currentSong: Song | null;
    protected currentVerseIndex: number;
    protected nextVerseSong: Song | null;
    protected nextVerseIndex: number;
    protected blankPresentation: boolean;

    protected currentSongTransformedLyrics: Song['lyrics'];
    protected nextSongTransformedLyrics: Song['lyrics'];


    //SongList Selection Related
    protected selectedSong: Song;
    protected selectedSongInListUid$ = new Subject<string>();

    //Controls
    private clickTimer: any = null;
    private isDoubleClick: boolean = false;
    protected nextVerseClickable: boolean = true;
    private userMadeLastNextVerseChange: boolean = false;
    protected fontSize: number;

    //Others
    protected chordsLyricsParserPipe: ChordsLyricsParserPipe;
    protected removeChordsParserPipe: RemoveChordsParserPipe;
    protected showChords: boolean;
    protected showNashville: boolean;
    private subscriptions: Subscription[] = [];

    private unsubscribe$ = new Subject<void>();
    //needs work and sorting
    protected verseCountForSelectedSong: number[] = [];
    protected verseCountForCurrentSong: number[] = [];

    protected verseCountForNextSong: number[] = [];
    private songVerseOrder: number[];
    private nextSongVerseOrder: number[];

    private selectedSongVerseOrder: number[];
    protected nextVerseKey: string;
    protected currentVerseKey: string;
    protected manualKey: boolean = false;
    protected keys: string[] = ['A', 'Bb', 'B', 'C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab'];


    constructor(
        private readonly firestore: ChorubyFirestoreService,
        private readonly messageService: CustomMessageService,
        private readonly currentSongService: CurrentSongServiceService,
        private readonly profile: ProfileService,
        // private readonly songsCacheService: SongsCacheService,
        private titleService: Title,
        private songControls: SongControlsService,
    ) {
        this.chordsLyricsParserPipe = new ChordsLyricsParserPipe();
        this.removeChordsParserPipe = new RemoveChordsParserPipe();
    }

    async ngOnInit() {

        this.titleService.setTitle('Dashboard');

        this.selectedSongInListUid$.pipe(
            takeUntil(this.unsubscribe$),
            switchMap((uid) => this.firestore.getSong$(uid)),
        ).subscribe((song) => {
            this.selectedSong = song;
            this.selectedSongVerseOrder = this.songControls.getVerseOrderIndex(song);
            this.selectedSong.lyrics = this.songControls.duplicateVersesInOrder(this.selectedSongVerseOrder, this.selectedSong);
            this.verseCountForSelectedSong = this.songControls.updateVerseNumberForSong(this.selectedSong);
        });

        this.subscriptions.push(
            this.currentSongService.getCurrentSongServiceList().subscribe((list) => {
                if (list)
                    this.currentList = list;
            })
        );

        this.subscriptions.push(
            this.currentSongService.getCurrentSong().subscribe((song) => {
                    this.currentSong = song;
                    if (song && this.currentSong) {
                        this.songVerseOrder = this.songControls.getVerseOrderIndex(song);
                        this.currentSongTransformedLyrics = this.songControls.duplicateVersesInOrder(this.songVerseOrder, this.currentSong);
                        this.verseCountForCurrentSong = this.songControls.updateVerseNumberForSong(song);
                        this.selectedSongInListUid$.next(this.currentSong.uid);
                    }
                }
            ));

        this.subscriptions.push(
            this.currentSongService.getCurrentVerseIndex().subscribe((index) => {
                this.currentVerseIndex = index;
            })
        );

        this.subscriptions.push(
            this.currentSongService.getNextSong().subscribe((song) => {

                this.nextVerseSong = song;
                if (song && this.nextVerseSong) {
                    this.nextSongVerseOrder = this.songControls.getVerseOrderIndex(song);
                    this.nextSongTransformedLyrics = this.songControls.duplicateVersesInOrder(this.nextSongVerseOrder, this.nextVerseSong);
                    this.verseCountForNextSong = this.songControls.updateVerseNumberForSong(song);
                }
            })
        );

        this.subscriptions.push(
            this.currentSongService.getNextVerseIndex().subscribe((index) => {
                    if (!this.userMadeLastNextVerseChange) {
                        this.nextVerseClickable = false;
                    }
                    this.userMadeLastNextVerseChange = false;

                    this.nextVerseIndex = index;

                    setTimeout(() => {
                        this.nextVerseClickable = true;
                    }, 3000);
                }
            )
        );


        this.subscriptions.push(
            this.currentSongService.getCurrentSongKey().subscribe((key) => {
                this.currentVerseKey = key;
            })
        );

        this.subscriptions.push(
            this.currentSongService.getNextSongKey().subscribe((key) => {
                this.nextVerseKey = key;
            })
        );

        this.subscriptions.push(
            this.currentSongService.getBlankPresentation().subscribe((blank) => {
                this.blankPresentation = blank;
            })
        );

        this.subscriptions.push(
            this.profile.getShowChords().subscribe((show) => {
                this.showChords = show;
            }));

        this.subscriptions.push(
            this.profile.getShowNashville().subscribe((show) => {
                this.showNashville = show;
            }));
    }

    nextVerseManualSelection(songUid: string, verseIndex: number, songKey: string) {
        this.userMadeLastNextVerseChange = true;
        const key = this.isNewSong(songUid) ? songKey : this.nextVerseKey;
        if (this.clickTimer == null) {
            this.clickTimer = setTimeout(() => {
                if (!this.isDoubleClick) {
                    this.firestore.setGlobalNextVerse(songUid, verseIndex, key).catch((error) => {
                        this.messageService.showError("Error", error);
                    })
                }
                this.clickTimer = null;
                this.isDoubleClick = false;
            }, 500);  // 500 ms delay; adjust to suit your needs
        } else {
            clearTimeout(this.clickTimer);
            this.clickTimer = null;
            this.isDoubleClick = true;
            this.firestore.setGlobalCurrentSongs(songUid, verseIndex, key).catch((error) => {
                this.messageService.showError("Error", error);
            });
            this.firestore.setGlobalNextVerse(songUid, verseIndex + 1, key).catch((error) => {
                this.messageService.showError("Error setting next verse", error);
            });

            this.isDoubleClick = false;
        }
    }

    noVerseManualSelection() {
        this.userMadeLastNextVerseChange = true;
        this.firestore.setGlobalNextVerse(this.currentSong?.uid ?? '0001', -1, this.currentVerseKey).catch((error) => {
            this.messageService.showError("Error setting next verse", error);
        });
    }

    switchNextVerseToCurrent() {
        console.log('manual key?', this.manualKey)
        if (this.nextVerseClickable) {
            this.userMadeLastNextVerseChange = true;
            if (this.nextVerseSong && this.nextVerseIndex !== null) {
                this.firestore.setGlobalCurrentSongs(this.nextVerseSong.uid, this.nextVerseIndex, this.nextVerseKey)
                    .catch((error) => {
                        this.messageService.showError("Error setting current verse", error);
                    });
                this.firestore.setGlobalNextVerse(this.nextVerseSong.uid, this.nextVerseIndex + 1, this.nextVerseKey).catch((error) => {
                    this.messageService.showError("Error setting next verse", error);
                });
                this.firestore.setGlobalKey(this.nextVerseKey).catch((error) => {
                    this.messageService.showError("Error setting key", error);
                });
            }
        }
    }

    protected keyChange(currentKey: string, keyUp: boolean) {
        const index = this.keys.findIndex(k => k === currentKey);
        const offset = keyUp ? 2 : -2 + this.keys.length;
        const newKey = this.keys[(index + offset) % this.keys.length];

        this.firestore.setGlobalNextKey(newKey).catch((error) => {
            this.messageService.showError("Error setting key", error);
        });
    }


    protected listSongSelectionChange(event: any) {
        if (event.uid) {
            this.selectedSongInListUid$.next(event.uid);
        }
    }


    parseLyricsContent(content: string, key?: string): string {
        if (this.showChords) {
            return this.chordsLyricsParserPipe.transform(content, key, this.showNashville);
        } else {
            return this.removeChordsParserPipe.transform(content);
        }
    }

    protected blank(blank: boolean) {
        this.firestore.blankPresentation(blank)
            .catch((error) => {
                this.messageService.showError("Error blanking presentation", error);
            });
    }

   protected isNewSong(songUid: string): boolean {
        return this.currentSong?.uid !== songUid;
    }


    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription?.unsubscribe();
        });

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }


}

