<div *ngIf="song" class="container mx-auto pt-5 pb-14 px-6 text-gray-800">
    <div class="flex gap-2 justify-between">
        <p (click)="goBack()" class="flex items-center text-chcaribbean cursor-pointer mb-2 text-sm"
        ><i class="pi pi-arrow-left mr-2"></i><span>Go Back</span></p>
        <div class="flex gap-4 justify-end mb-6">

            <button class="flex justify-center items-center rounded-md border bg-chcaribbean hover:bg-chprimary
                        border-transparent py-3.5 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-chprimary focus:ring-offset-2"
                    routerLink="/song/{{song.uid}}/edit"
            >
                <i class="pi pi-pencil md:mr-1"></i><span class="hidden md:inline">Edit Song</span>
            </button>
            <button (click)="playSongNow(song.uid, song.key)" class="flex justify-center items-center rounded-md border bg-chgforest hover:bg-chcaribbean
                        border-transparent py-3.5 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-chprimary focus:ring-offset-2"
            >
                <i class="pi pi-play md:mr-1"></i><span class="hidden md:inline">Present Now</span>
            </button>
            <button (click)="addSongToPresentedList(song.uid)"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                        border-transparent py-2 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-chprimary focus:ring-offset-2">
                <svg class="w-6 h-6 md:mr-1"
                     fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                          stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
                <span class="hidden md:inline">Add to Presenting List</span>
            </button>
            <button (click)="addSongToCurrentSelectedList(song.uid)"
                    class="flex gap-1 justify-center items-center rounded-md border bg-chcoral hover:bg-chcaribbean
                        border-transparent py-3.5 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-chprimary focus:ring-offset-2">
                <i class="pi pi-user md:mr-1"></i><span class="hidden md:inline">Add to My Selected List</span>
            </button>
        </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-5 gap-6 grid-flow-dense">

        <div class="lg:row-start-1 lg:row-end-2 lg:col-start-5 lg:col-end-6">
            <div class="flex justify-between gap-2 p-4">
                <button *ngIf="song.uid != '0001'" (click)="previousSong()" class="flex justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                        border-transparent py-3.5 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-chprimary focus:ring-offset-2"
                >Previous
                </button>
                <button (click)="nextSong()" class="flex justify-center items-center rounded-md border bg-chprimary hover:bg-chcaribbean
                        border-transparent py-3.5 px-3.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-chprimary focus:ring-offset-2"
                >Next
                </button>

            </div>

            <div class=" p-4 shadow rounded-md bg-white">
                <h3 class="text-base font-medium uppercase text-gray-500">Basic Song Information</h3>
                <div class="border-t border-gray-200 mb-2 mt-1"></div>
                <div class="flex items-center mb-4">
                    <label class="text-sm font-medium text-chcaribbean mr-2">Number:</label>
                    <p class="font-medium text-chprimary">{{song.uid}}</p>
                </div>
                <div class="flex lg:flex-col xl:flex-row lg:items-start items-center mb-4">
                    <label class="text-sm font-medium text-chcaribbean mr-2">Title:</label>
                    <p class="font-medium text-chprimary">{{song.title}}</p>
                </div>
                <div class="flex items-center mb-4">
                    <label class="text-sm font-medium text-chcaribbean mr-2">Key:</label>
                    <p class="font-medium text-chprimary">{{song.key}}</p>
                </div>
                <div class="flex items-center mb-4">
                    <label class="text-sm font-medium text-chcaribbean mr-2">Tempo:</label>
                    <p class="font-medium text-chprimary">{{song.tempo}}</p>
                </div>
                <div class="flex items-center mb-4">
                    <label class="text-sm font-medium text-chcaribbean mr-2">Usage:</label>
                    <p class="font-medium text-chprimary">Coming soon...</p>
                </div>
            </div>
        </div>

        <!--LYRICS-->
        <div *ngFor="let lyric of song.lyrics; let i=index" class="lg:col-span-2 p-4 shadow rounded-md bg-white">

            <h3 class="text-base font-medium uppercase text-gray-500">
                {{lyric.type === 'Verse' ? 'Verse ' + (i + 1 - countChorusBefore(i)) : lyric.type}}
            </h3>
            <div class="border-t border-gray-200 mb-5 mt-1"></div>
            <p [innerHTML]="parseLyricsContent(lyric.content, song.key)" [ngClass]="{'leading-chords': showChords}"
               [ngStyle]="{'white-space': 'pre-wrap'}"
               class="leading-loose"></p>
        </div>


        <!--AUDIT LOG-->
        <div class="lg:row-start-2 lg:row-end-5 lg:col-start-5 lg:col-end-6 p-4">
            <h3 class="text-base font-medium uppercase text-chprimary mb-4">Song Timeline</h3>
                        <ng-container *ngIf="(auditLogs$ | async) as auditLogs">
                            <ol class="relative border-l border-gray-400">
                                <li *ngFor="let item of auditLogs" class="mb-10 ml-4">
                                    <div class="absolute w-3 h-3 bg-chcaribbean rounded-full mt-1.5 -left-1.5 border border-white"></div>
                                    <time class="mb-1 text-sm font-normal leading-none text-gray-500">{{(item?.date.toDate()   | date:'dd/MM/yyyy hh:mm') ?? ''}}</time>
                                    <h3 class="text-base text-gray-900">{{item?.modified}}</h3>
                                    <p class="mb-4 text-sm font-normal text-gray-500">
                                        by {{item?.person?.firstName}} {{item?.person?.lastName}}</p>
                                </li>
                            </ol>
                        </ng-container>
        </div>

        <!--AUTHOR AND COPYRIGHT-->
        <div class="lg:col-span-4 p-4">
            <h3 class="text-base font-medium uppercase text-gray-500">Author and Copyright Info</h3>
            <div class="border-t border-gray-200 mb-2 mt-1"></div>
            <div class="grid gap-4 grid-cols-3">
                <div>
                    <label class="block mt-6 mb-1 text-sm font-medium text-chcaribbean">Author</label>
                    <p>{{song?.author}}</p>
                </div>
                <div>
                    <label class="block mt-6 mb-1 text-sm font-medium text-chcaribbean">Copyright</label>
                    <p>{{song?.copyright}}</p>
                </div>
            </div>

        </div>
    </div>
</div>


<div *ngIf="!song" class="w-full h-[70vh] flex justify-center items-center bg-gray-100">
    <div class="text-center text-gray-600 font-medium text-2xl overflow-y-scroll">Loading Song...</div>
</div>