export const environment = {
  production: true,
  firebase: {
    projectId: 'choruby-app',
    appId: '1:657419624300:web:b27dedf7b717dd17cddd49',
    storageBucket: 'choruby-app.appspot.com',
    apiKey: 'AIzaSyBwpQ48sJUmnnPwXFepdCrrEH6I8JQXys8',
    authDomain: 'choruby-app.firebaseapp.com',
    messagingSenderId: '657419624300',
    measurementId: 'G-XF5LZ3EC3Q',
  },
};
