import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomMessageService} from "../../../core/services/custom-message.service";
import {Profile, SongList, ListType} from "../../../core/models";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChorubyFirestoreService} from "../../../core/services/choruby-firestore.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {firstValueFrom, Subscription} from "rxjs";
import {take} from "rxjs/operators";
import {ProfileService} from "../../../core/services/profile.service";

@Component({
    selector: 'app-list-edit',
    templateUrl: './list-edit.component.html',
    providers: [CustomMessageService]
})
export class ListEditComponent implements OnInit, OnDestroy {

    protected isProcessing = false;
    protected list: SongList;
    protected listForm: FormGroup = this.fb.group({
        title: ['', [Validators.required]],
        date: ['', [Validators.required]],
        status: ['Active'],
    });
    private subscriptions: Subscription[] = [];
    private currentUser: Profile | null;
    protected listOptions = Object.values(ListType).map(tempo => ({value: tempo}));

    constructor(
        private customMessageService: CustomMessageService,
        private firestore: ChorubyFirestoreService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private fb: FormBuilder,
        private readonly profile: ProfileService
    ) {
    }


    async ngOnInit() {
        this.titleService.setTitle('Edit List');
        const listId = this.route.snapshot.paramMap.get('listId');
        if (listId) {
            await this.getList(listId ?? '');
        }

    }

    //todo: make so it doesn't change while editing
    async getList(listId: string) {

        this.list = await firstValueFrom(this.firestore.getSongList$(listId));
        if (this.list) {
            this.patchSongToForm();
        }
    }

    patchSongToForm(): void {
        this.listForm.patchValue({
            title: this.list.title,
            date: this.list.date.toDate(),
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            subscription?.unsubscribe();
        });
    }

    protected async onSubmit() {
        this.isProcessing = true;
        const formValue = this.listForm.getRawValue();
        const uid = this.list?.uid ?? '';

        if (uid) {
            await this.firestore.updateSongList(formValue, uid).then(() => {
                this.customMessageService.showSuccess('Success', 'List updated successfully');
                this.router.navigate([`/song-list/${uid}/view`]);
            }).catch((error) => {
                this.customMessageService.showError('Error', 'List update failed');
            });
        } else {
            const userProfile$ = this.profile.getProfile();
            this.subscriptions.push(
                userProfile$.pipe(take(1)).subscribe(async (profile: any) => {
                    this.currentUser = await profile;
                    formValue.listOwnerName = this.currentUser?.firstName + ' ' + this.currentUser?.lastName;
                    formValue.listOwnerEmail = this.firestore.getUserEmail();
                    console.log(formValue);

                    await this.firestore.createSongList(formValue).then((docRef) => {
                        this.customMessageService.showSuccess('Success', 'List created successfully');
                        const listId = docRef.id;
                        this.firestore.setPersonalSelectedSongList(listId).catch((error) => {
                            this.customMessageService.showError('Error', 'Selecting newly created list failed');
                        });
                        this.router.navigate([`/song-list/${listId}/view`]);

                    }).catch((error) => {
                        this.customMessageService.showError('Error', 'List creation failed');
                    });
                }));
        }
        this.isProcessing = false;
    }
}

